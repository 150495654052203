const jsonToProps = ({
  full_address,
  unit_title,
  unit_list_title,
  congrats_quote,
  tooltip_with_icon,
  is_show_price,
  ...rest
}) => ({
  fullAddress: full_address,
  unitTitle: unit_title,
  unitListTitle: unit_list_title,
  congratsQuote: congrats_quote,
  guestsTooltip: tooltip_with_icon,
  isShowPrice: is_show_price,
  ...rest,
});

export default jsonToProps;
