import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import get from 'lodash/get';
import withMappedProps from '../../utils/withMappedProps';
import ContainerActions from '../buybox/actions/container-actions';
import ComponenList from '../component-list';
import ImageComponent from '../image/image-component';
import StyledLabel from '../styled-label';
import { imageToProps } from '../image/image-to-props';
import labelToProps from '../styled-label/label-to-props';

const namespace = 'ui-pdp-whatsapp-card';

const BASE_COMPONENTS = {
  image_component: withMappedProps(ImageComponent)(imageToProps),
  label_component: withMappedProps(StyledLabel)(labelToProps),
};

const createActionHandler = containerActions => () => {
  const target = get(containerActions, '[0].actions[0].target', null);

  if (window && target) {
    window.open(target);
  }
};

const createActionsToPropsMapper = itemId => ({ container_actions: containerActions, ...props }) => ({
  ...props,
  itemId,
  containerActions,
  fetchContactInfo: createActionHandler(containerActions),
  onRequestFocus: () => null,
});

const WhatsappCard = ({ itemId, components }) => {
  const availableComponents = {
    ...BASE_COMPONENTS,
    actions_component: withMappedProps(ContainerActions)(createActionsToPropsMapper(itemId)),
  };

  return (
    <div className={namespace}>
      <ComponenList availableComponents={availableComponents} initialState={{ components }} modifier="whatsapp" />
    </div>
  );
};

WhatsappCard.propTypes = {
  itemId: string.isRequired,
  components: arrayOf(shape({})).isRequired,
};

export default WhatsappCard;
