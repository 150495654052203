import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import classnames from 'classnames';
import StyledLabel from '../styled-label';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-pdp-highlighted-specs-res';

const HighlightedSpecsRes = ({ specs, className }) => (
  <div className={classnames(namespace, className)}>
    {specs.map(spec => (
      <div key={spec.icon.id} className={`${namespace}__icon-label`}>
        <div> {IconFactory(spec.icon)}</div>
        <StyledLabel as="span" className="ui-pdp-label" {...spec.label} />
      </div>
    ))}
  </div>
);

HighlightedSpecsRes.propTypes = {
  className: string,
  specs: arrayOf(
    shape({
      icon: shape({
        id: string.isRequired,
        color: string,
        size: string,
      }),
      label: shape({
        text: string.isRequired,
        color: string,
        font_size: string,
        font_family: string,
      }),
    }),
  ).isRequired,
};

HighlightedSpecsRes.defaultProps = {
  className: null,
};

export default HighlightedSpecsRes;
