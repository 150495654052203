import React, { useContext, useState } from 'react';
import { string, shape, arrayOf, number, func } from 'prop-types';
import CarouselSnapped, { CarouselSnappedSlide } from '@andes/carousel-snapped';
import { ACTION_TYPES } from '../buybox/actions/utils/action-types';
import StyledLabel from '../styled-label';
import ModelsCard from './models-card';
import QuotationRequestModal from '../../pages/vip/features/quotations/quotation-request-modal';
import UnregisteredQuotationRequestModal from '../../pages/vip/features/quotations/unregistered-quotation-request-modal';
import Quotation from '../../pages/vip/features/quotations/quotation.desktop';
import RequestLTRUnitsModal from '../../pages/vip/features/multifamily/multifamily-request-modal';
import Multifamily from '../../pages/vip/features/multifamily/multifamily.desktop';
import StaticPropsContext from '../context/static-props';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-vip-models-carousel';

const selectModal = actionType => {
  switch (actionType) {
    case ACTION_TYPES.MODAL_QUOTATION:
      return QuotationRequestModal;
    case ACTION_TYPES.MODAL_CHECK_UNITS:
      return RequestLTRUnitsModal;
    default:
      return UnregisteredQuotationRequestModal;
  }
};

const selectContentComponent = (actionType, props) => {
  switch (actionType) {
    case ACTION_TYPES.MODAL_CHECK_UNITS:
      return <Multifamily {...props} />;
    default:
      return <Quotation {...props} />;
  }
};

const ModelsCarouselDesktop = ({ title, models, actionType, recaptchaSiteKey }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  const RequestModal = selectModal(actionType);

  const handleClick = model => {
    if (model.action) {
      trackEvent(model.action.track);
    }

    setIsModalOpen(true);
    setSelectedModel(model.id);
  };

  const handleKeyPress = (event, model) => {
    if (event.key === 'Enter' || event.keyCode === 13 || event.which === 13) {
      handleClick(model);
    }
  };

  // eslint-disable-next-line react/prop-types
  const SlideModel = ({ model, ...rest }) => (
    <div
      className={`${namespace}__card-wrapper`}
      role="button"
      tabIndex="0"
      onClick={() => handleClick(model)}
      onKeyPress={e => handleKeyPress(e, model)}
    >
      <ModelsCard className={`${namespace}__model_card`} model={model} {...rest} />
    </div>
  );

  return (
    <div className={namespace}>
      <StyledLabel className={`${namespace}__title`} as="h2" {...title} />
      <CarouselSnapped
        srLabel={title.text}
        arrowsVisible={false}
        slidesPerView={Math.min(models.length, 4)}
        pagination={false}
        type="content"
        spacing={16}
        arrowsSize="small"
        strictBoundaries={false}
        lazyload={false}
      >
        {models.map(model => (
          <CarouselSnappedSlide
            // eslint-disable-next-line react/prop-types
            key={`model_${model.id}`}
            className={models.length < 4 ? `${namespace}__wrapper-slide` : ''}
          >
            <SlideModel model={model} />
          </CarouselSnappedSlide>
        ))}
      </CarouselSnapped>
      <RequestModal
        modalClassName={`${namespace}__modal`}
        isOpen={isModalOpen}
        modelId={selectedModel}
        deviceType={deviceType}
        onClose={() => setIsModalOpen(false)}
        contentComponent={selectContentComponent(actionType, {
          modelCarouselSelected: selectedModel,
          recaptchaSiteKey,
        })}
      />
    </div>
  );
};

ModelsCarouselDesktop.propTypes = {
  title: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
  }).isRequired,
  models: arrayOf(
    shape({
      id: number,
      image: shape({
        id: string,
        alt: string,
        width: number,
        height: number,
        url: shape({
          src: string,
          srcx2: string,
        }),
      }),
      action: shape({
        track: shape({}),
      }),
    }),
  ).isRequired,
  actionType: string.isRequired,
  getModelUnits: func.isRequired,
  recaptchaSiteKey: string,
};

ModelsCarouselDesktop.defaultProps = {
  recaptchaSiteKey: null,
};

export default React.memo(ModelsCarouselDesktop);
