import React from 'react';
import { Action } from '../../prop-types';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-pdp-print_to_pdf';

const PrintToPdf = ({ action }) => (
  <div className={namespace}>
    <a
      href={action.target}
      className={`${namespace}__link`}
      target={action.html_target}
      onClick={() => {
        trackEvent(action.track);
      }}
    >
      {action.label.text}
    </a>
  </div>
);

PrintToPdf.propTypes = {
  action: Action.isRequired,
};

export default PrintToPdf;
