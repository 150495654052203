import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import WhatsappCard from '../../../../components/whatsapp-card';

const FEATURE_NAME = 'contact_whatsapp_promotion';

const mapStateToProps = ({
  id,
  components: {
    [FEATURE_NAME]: { components },
  },
}) => ({
  itemId: id,
  components,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(WhatsappCard);
