import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip/vis';
import SellerProfile from '../../../../components/seller-profile/seller-profile.desktop';

const FEATURE_NAME = 'seller_profile_rex';

const mapStateToProps = ({ [FEATURE_NAME]: sellerProfile, id, doContactAction, layout, recaptchaSiteKey }) => {
  const { message, isFetchingData, phones, recaptchaPublicKey } = sellerProfile;
  const statusInfoVisProps = { message, isFetchingData, phones, recaptchaPublicKey, itemId: id };
  const newSellerProfile = {
    phoneLink: sellerProfile.phone_link,
    extraInfo: sellerProfile.extra_info,
    sellerName: { ...sellerProfile.seller_name },
    userStatusTooltip: sellerProfile.user_status_tooltip,
    containerActions: sellerProfile.container_actions,
    bottomExtraInfo: sellerProfile.bottom_extra_info,
    tagsReputationAlign: sellerProfile.tags_reputation_align,
    linkLabel: sellerProfile.link_label,
    recaptchaSiteKey,
    doContactAction,
    layout,
    featureName: FEATURE_NAME,
    ...sellerProfile,
  };
  return { ...newSellerProfile, statusInfoVisProps };
};

const mapDispatchToProps = dispatch => ({
  fetchContactInfo: ({
    itemId,
    contactType,
    recaptchaToken,
    message,
    recaptchav2token,
    action,
    ajaxBackendParams,
    recaptchaSiteKey,
  }) =>
    dispatch(
      actions.fetchContactInfo({
        itemId,
        contactType,
        recaptchaToken,
        message,
        recaptchav2token,
        component: FEATURE_NAME,
        action,
        ajaxBackendParams,
        recaptchaSiteKey,
      }),
    ),
  redirectToLogin: (itemId, featureName, loginType) =>
    dispatch(actions.redirectToLogin({ itemId, featureName, loginType })),
  onScrollComponent: focus => dispatch(actions.onScrollComponent({ component: FEATURE_NAME, focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(SellerProfile);
