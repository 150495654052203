import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/location/jsonToProps';
import * as actions from '../../../../actions/vip/vis';

const FEATURE_NAME = 'location_and_points';

const mapStateToProps = ({ [FEATURE_NAME]: locationAndPoints }) => ({
  featureName: FEATURE_NAME,
  ...jsonToProps(locationAndPoints),
});

const mapDispatchToProps = dispatch => ({
  onScrollComponent: focus => dispatch(actions.onScrollComponent({ component: FEATURE_NAME, focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
