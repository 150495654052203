import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions/vip/vis';
import Multifamily from '../../../../components/multifamily/multifamily.desktop';
import jsonToProps from '../../../../components/quotations/jsonToProps';
import modelsJsonToProps from '../../../../components/models-carousel/jsonToProps';

const FEATURE_NAME = 'multifamily';

const mapStateToProps = (
  { [FEATURE_NAME]: multifamily, picture_config },
  { resizeModal, modelCarouselSelected, recaptchaSiteKey },
) => ({
  ...jsonToProps(multifamily),
  models: modelsJsonToProps({ elements: multifamily.models, picture_config }),
  modelUnits: multifamily.model_units,
  modelCarouselSelected,
  currentState: multifamily.currentState,
  recaptchaSiteKey,
  resizeModal,
});

const mapDispatchToProps = dispatch => ({
  getUnitsLTR: ({ modelId, deviceType, currentState }) => {
    dispatch(actions.getUnitsLTR(modelId, deviceType, currentState));
  },
  updateCurrentState: currentState => {
    dispatch(actions.updateLTRUnitsCurrentState(currentState));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Multifamily);
export { mapDispatchToProps };
