import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import Promotion from '../../../../components/promotions';

const FEATURE_NAME = 'label_rental_type';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: { labels, type },
    },
  },
  ownProps,
) => ({
  labels,
  type,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Promotion);
