import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/available-units/jsonToProps';

const FEATURE_NAME = 'models';

const mapStateToProps = ({ components: { [FEATURE_NAME]: availableUnits }, picture_config, id }) => ({
  itemId: id,
  content_rows: jsonToProps({ ...availableUnits, picture_config }),
  title: availableUnits.title,
  init_row_size: availableUnits.init_row_size,
  actions: availableUnits.actions,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
