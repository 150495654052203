import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import PiStatistics from '../../../../components/pi-statistics/pi-statistics';

const FEATURE_NAME = 'pi_statistics_url';

const mapStateToProps = ({ components: { [FEATURE_NAME]: piStatistics } }) => ({ ...piStatistics });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(PiStatistics);
