import { idToUrl } from '../../lib/id-to-url';

const jsonToProps = ({ content_rows, picture_config }) => {
  const {
    template,
    template_2x: template2x,
    template_zoom: templateZoom,
    template_zoom_2x: templateZoom2x,
    template_thumbnail: templateThumbnail,
    template_thumbnail_2x: templateThumbnail2x,
  } = picture_config;

  content_rows.map(row => {
    row.pictures = row.pictures.map(picture => {
      if (picture.url) {
        return {
          src: picture.url.src,
          src2x: picture.url.src2x,
          width: picture.width || 500,
          height: picture.height || 500,
        };
      }

      return {
        ...picture,
        width: picture.width || 500,
        height: picture.height || 500,
        src: idToUrl(picture.id, template),
        src2x: template2x ? idToUrl(picture.id, template2x) : null,
        thumbnail: {
          src: templateThumbnail ? idToUrl(picture.id, templateThumbnail) : null,
          src2x: templateThumbnail2x ? idToUrl(picture.id, templateThumbnail2x) : null,
        },
        zoom: {
          src: templateZoom ? idToUrl(picture.id, templateZoom) : null,
          src2x: templateZoom2x ? idToUrl(picture.id, templateZoom2x) : null,
        },
      };
    });

    return { ...row };
  });

  return content_rows;
};

export default jsonToProps;
