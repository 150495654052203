import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';
import StyledLabel from '../styled-label';

const namespace = 'ui-pdp-maintenance-fee-ltr';

const MaintenanceFeeLTR = ({ label, className }) => (
  <StyledLabel as="p" className={classnames(namespace, className)} {...label} />
);

MaintenanceFeeLTR.propTypes = {
  className: string,
  label: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
  }).isRequired,
};

MaintenanceFeeLTR.defaultProps = {
  className: null,
};

export default MaintenanceFeeLTR;
