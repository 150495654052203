import React, { Fragment, useEffect, useState } from 'react';
import { arrayOf, number, string, bool, shape, node, func } from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from '@andes/tooltip';
import StyledLabel from '../styled-label';
import MediaAction from '../media/media-action';
import { generateKeyToComponent } from '../../utils/validators';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-pdp-chart';
const namecolorspace = 'ui-pdp-background-color';

const Bar = ({ className, height, width }) => (
  <div data-testid="chart-bar" className={className} style={{ height, width }} />
);

Bar.propTypes = {
  className: string,
  height: number,
  width: string,
};

const Line = ({ className }) => <div className={className} />;

Line.propTypes = {
  className: string,
};

const Space = ({ className }) => <div className={className} />;

Space.propTypes = {
  className: string,
};

const TooltipHover = ({ className, children, hoverInfo, isVisible, onOpen }) => (
  <Tooltip
    className={className}
    content={<StyledLabel {...hoverInfo.body} />}
    actions={<MediaAction {...hoverInfo.action} />}
    open={isVisible}
    autoHideDelay={0}
    onOpen={onOpen}
  >
    {children}
  </Tooltip>
);

TooltipHover.propTypes = {
  className: string,
  children: node,
  hoverInfo: shape({
    body: shape({}),
    action: shape({}),
  }),
  isVisible: bool,
  onOpen: func,
  onClose: func,
};

TooltipHover.defaultProps = {
  className: '',
  hoverInfo: null,
};

const BarsContainer = ({ barsContainerNamespace, barsHeight, padding, barsHoverInfo }) => {
  const [activeTooltip, setActiveTooltip] = useState(null);
  const hasHoverSupport = barsHoverInfo && barsHoverInfo.length === barsHeight.length;

  const barWidth = `calc(100% / ${barsHeight.length})`;
  return (
    <div
      className={`${barsContainerNamespace}__bars-container`}
      style={{ paddingLeft: padding, paddingRight: padding }}
    >
      {barsHeight &&
        barsHeight.map((barHeight, index) => (
          <Fragment key={generateKeyToComponent(barHeight)}>
            {hasHoverSupport ? (
              <TooltipHover
                className={`${barsContainerNamespace}__hover-tooltip`}
                hoverInfo={barsHoverInfo[index.valueOf()]}
                isVisible={index === activeTooltip}
                onOpen={() => setActiveTooltip(index)}
              >
                <Bar
                  className={classnames(`${barsContainerNamespace}__bar`, {
                    [`${barsContainerNamespace}__bar-hover`]: hasHoverSupport,
                  })}
                  height={barHeight}
                  width="24px"
                />
              </TooltipHover>
            ) : (
              <Bar className={`${barsContainerNamespace}__bar`} height={barHeight} width={barWidth} />
            )}
            {index !== barsHeight.length - 1 && <Space className={`${barsContainerNamespace}__space`} />}
          </Fragment>
        ))}
    </div>
  );
};

BarsContainer.propTypes = {
  barsContainerNamespace: string,
  barsHeight: arrayOf(number),
  barsHoverInfo: arrayOf(shape({})),
  padding: number,
};

const Flag = ({ tooltipNamespace, valueClassName, lineClassName, tooltipPosition, tooltipValue, icon, poleSize }) => (
  <div className={tooltipNamespace} style={{ left: tooltipPosition.position }}>
    <div
      className={classnames(valueClassName, `${namecolorspace}--${tooltipValue.bg_color}`)}
      style={{ left: tooltipPosition.valuePosition }}
    >
      <StyledLabel {...tooltipValue} />
      {icon && IconFactory(icon)}
    </div>
    <Line
      className={classnames(
        lineClassName,
        `${namecolorspace}--${tooltipValue.bg_color}`,
        `${namespace}__pole-size--${poleSize}`,
      )}
    />
  </div>
);

Flag.propTypes = {
  tooltipNamespace: string,
  valueClassName: string,
  lineClassName: string,
  poleSize: string,
  tooltipPosition: shape({ position: number, valuePosition: number, visible: bool }),
  tooltipValue: shape({
    text: string,
    color: string,
    bg_color: string,
    font_size: string,
    font_family: string,
  }),
  icon: shape({
    id: string,
    color: string,
  }),
};

const LimitsContainer = ({ limitsNamespace, lowerBand, middleBand, upperBand }) => (
  <>
    <div className={`${limitsNamespace}__limit-lines`}>
      <Line className={`${limitsNamespace}__lower`} />
      {middleBand && <Line className={`${limitsNamespace}__middle`} />}
      <Line className={`${limitsNamespace}__upper`} />
    </div>
    <div className={`${limitsNamespace}__limit-values`}>
      <div className={`${limitsNamespace}__lower`}>{lowerBand}</div>
      {middleBand && <div className={`${limitsNamespace}__middle`}>{middleBand}</div>}
      <div className={`${limitsNamespace}__upper`}>{upperBand}</div>
    </div>
  </>
);

LimitsContainer.propTypes = {
  limitsNamespace: string,
  lowerBand: string,
  middleBand: string,
  upperBand: string,
};

const getBarsHeight = value => {
  const maxBarHeigth = 48;
  return value * maxBarHeigth;
};

const calculateLimitPosition = (setWidth, component) => {
  const element = document.getElementsByClassName(component)[0];
  const width = element ? element.offsetWidth : 0;
  setWidth({ width, visible: true });
};

const isOutOfLowerRange = (position, valuePosition) => position < valuePosition * -1;

const isOutOfUpperRange = (position, tooltipWidth, valuePosition, containerWidth) =>
  position + tooltipWidth + valuePosition > containerWidth;

const calculateTooltipPosition = (setPosition, percentilePosition, tooltip, padding) => {
  const container = document.getElementsByClassName(`${namespace}__graph`)[0];
  const bar = document.getElementsByClassName(`${namespace}__bar`)[0];
  const space = document.getElementsByClassName(`${namespace}__space`)[0];
  const tooltipComponent = document.getElementsByClassName(tooltip)[0];
  const spaceBetweenBars = space.offsetWidth;

  if (container && tooltipComponent && bar) {
    const tooltipWidth = tooltipComponent.offsetWidth;
    const barWidth = bar.offsetWidth;

    const initialPosition = padding - barWidth / 2 - spaceBetweenBars;
    const barOffset = Math.trunc(percentilePosition * 10);

    const position = initialPosition + barWidth * barOffset + spaceBetweenBars * barOffset;
    let valuePosition = 0 - tooltipWidth / 2;

    if (isOutOfLowerRange(position, valuePosition)) {
      valuePosition = position * -1;
    } else if (isOutOfUpperRange(position, tooltipWidth, valuePosition, container.offsetWidth)) {
      valuePosition = (tooltipWidth - (container.offsetWidth - position)) * -1;
    }

    // TODO: validar valores NaN
    setPosition({ position, valuePosition, visible: true });
  }
};

const Chart = ({
  pricesDistribution,
  barsHoverInfo,
  lowerBand,
  middleBand,
  upperBand,
  tooltip: { percentile_position: percentilePosition, data: tooltipValue, ...tooltipRest },
  tooltipFipe,
  padding,
}) => {
  const [lowerLimitSize, setLowerLimitSize] = useState({ width: 0, visible: false });
  const [upperLimitSize, setUpperLimitSize] = useState({ width: 0, visible: false });
  const [tooltipPosition, setTooltipPosition] = useState({ position: 0, valuePosition: 0, visible: false });
  const [tooltipFipePosition, setTooltipFipePosition] = useState({ position: 0, valuePosition: 0, visible: false });

  useEffect(() => {
    calculateLimitPosition(setLowerLimitSize, `${namespace}__lower`);
    calculateLimitPosition(setUpperLimitSize, `${namespace}__upper`);
    calculateTooltipPosition(setTooltipPosition, percentilePosition, `${namespace}__tooltip-value`, padding);
    if (tooltipFipe) {
      calculateTooltipPosition(
        setTooltipFipePosition,
        tooltipFipe.percentile_position,
        `${namespace}__tooltip-fipe-value`,
        padding,
      );
    }
  }, [percentilePosition, padding, tooltipFipe]);

  const barsHeight = pricesDistribution.map(value => getBarsHeight(value));

  const graphSize = tooltipRest.pole_size ? tooltipRest.pole_size.toLowerCase() : 'default';

  return (
    <div className={namespace}>
      <div className={classnames(`${namespace}__graph`, `${namespace}__graph-size--${graphSize}`)}>
        <BarsContainer
          barsContainerNamespace={namespace}
          barsHeight={barsHeight}
          padding={padding}
          barsHoverInfo={barsHoverInfo}
        />
        <Flag
          tooltipNamespace={classnames(`${namespace}__tooltip`, {
            [`${namespace}--visible`]: tooltipPosition.visible,
            [`${namespace}--alone`]: !tooltipFipe,
          })}
          valueClassName={`${namespace}__tooltip-value`}
          lineClassName={`${namespace}__tooltip-line`}
          tooltipPosition={tooltipPosition}
          tooltipValue={tooltipValue}
          poleSize={graphSize}
          {...tooltipRest}
        />
        {tooltipFipe && (
          <Flag
            tooltipNamespace={classnames(`${namespace}__tooltip`, `${namespace}__tooltip-fipe`, {
              [`${namespace}--visible`]: tooltipFipePosition.visible,
            })}
            valueClassName={`${namespace}__tooltip-value ${namespace}__tooltip-fipe-value`}
            lineClassName={`${namespace}__tooltip-line ${namespace}__tooltip-fipe-line`}
            tooltipPosition={tooltipFipePosition}
            tooltipValue={tooltipFipe.data}
          />
        )}
      </div>
      <Line className={`${namespace}__chart-line`} />
      <LimitsContainer
        limitsNamespace={namespace}
        lowerBand={lowerBand}
        middleBand={middleBand}
        upperBand={upperBand}
        upperLimitSize={upperLimitSize}
        lowerLimitSize={lowerLimitSize}
      />
    </div>
  );
};

/* PropTypes */
Chart.propTypes = {
  pricesDistribution: arrayOf(number),
  barsHoverInfo: arrayOf(shape({})),
  lowerBand: string,
  upperBand: string,
  middleBand: string,
  tooltip: shape({
    data: shape({
      text: string,
      color: string,
      bg_color: string,
      font_size: string,
      font_family: string,
    }),
    icon: shape({
      id: string,
      color: string,
    }),
    percentile_position: number,
  }),
  tooltipFipe: shape({
    data: shape({
      text: string,
      color: string,
      bg_color: string,
      font_size: string,
      font_family: string,
    }),
    percentile_position: number,
  }),
  padding: number,
  spaceBetweenBars: number,
};

/* Default Props */

Chart.defaultProps = {
  pricesDistribution: null,
  barsHoverInfo: null,
  lowerBand: '',
  upperBand: '',
  middleBand: '',
  tooltip: null,
  tooltipFipe: null,
  padding: 48,
  spaceBetweenBars: 4,
};

Flag.defaultProps = {
  tooltipNamespace: '',
  valueClassName: '',
  poleSize: '',
  lineClassName: '',
  tooltipPosition: null,
  tooltipValue: null,
  icon: null,
};

BarsContainer.defaultProps = {
  barsContainerNamespace: '',
  barsHeight: null,
  barsHoverInfo: null,
  padding: null,
};

Bar.defaultProps = {
  className: '',
  height: 0,
  width: '',
};

Line.defaultProps = {
  className: '',
};

Space.defaultProps = {
  className: '',
};

LimitsContainer.defaultProps = {
  limitsNamespace: '',
  lowerBand: '',
  middleBand: '',
  upperBand: '',
};

export default Chart;
