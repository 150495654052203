import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import OnlineVisitRequest from '../../../../components/online-visit-request-label/online-visit-request-label';

const ONLINE_VISIT_REQUEST = 'online_visit_request';

const mapStateToProps = ({ components: { [ONLINE_VISIT_REQUEST]: onlineVisitRequestLabel } }) => ({
  onlineVisitRequestLabel,
});

const enhance = compose(
  withFeatureFlag(ONLINE_VISIT_REQUEST),
  withRow(ONLINE_VISIT_REQUEST),
  connect(mapStateToProps),
);

export default enhance(OnlineVisitRequest);
export { mapStateToProps };
