import * as actionsVis from '../../../../actions/vip/vis';
import transformActionTarget from '../../../../utils/transformActionTarget';
import * as actionsVip from '../../../../actions/vip';

const mapStateToProps = FEATURE_NAME => (
  {
    [FEATURE_NAME]: {
      id,
      form,
      question_message,
      quick_access,
      track,
      show_track,
      title,
      messages,
      disabled,
      url,
      stockModal,
      questions,
      responseToken,
      ...rest
    },
    baseUrl,
    recaptchaPublicKey,
    recaptchaSiteKey,
    component,
    id: itemId,
  },
  { enableRedirectAction },
) => {
  if (quick_access && quick_access.actions) {
    quick_access.actions.forEach(element => {
      if (element.action_type !== 'SCROLL_TO') {
        transformActionTarget(element, { controlled: 'true' });
      }
    });
  }

  return {
    id,
    form,
    question_message,
    quick_access,
    title,
    track,
    show_track,
    messages,
    disabled,
    enableRedirectAction,
    itemId,
    url: `${baseUrl}/${itemId}/questions`,
    stockModal,
    recaptchaPublicKey,
    recaptchaSiteKey,
    component,
    responseToken,
    ...rest,
    ...questions,
  };
};

const mapDispatchToProps = FEATURE_NAME => dispatch => ({
  onCreateQuestion: ({ itemId, text, track, checkbox }) =>
    dispatch(actionsVis.onCreateOnlyQuestion({ itemId, text, track, component: FEATURE_NAME, checkbox })),
  fetchContactInfo: ({ itemId, contactType, recaptchav2token, message, action, ajaxBackendParams, recaptchaSiteKey }) =>
    dispatch(
      actionsVis.fetchContactInfo({
        component: FEATURE_NAME,
        itemId,
        contactType,
        recaptchav2token,
        message,
        action,
        ajaxBackendParams,
        recaptchaSiteKey,
      }),
    ),
  onRequestFocus: ({ focus }) => dispatch(actionsVis.onScrollComponent({ component: FEATURE_NAME, focus })),
  updateTokenResponse: ({ token }) => dispatch(actionsVis.updateTokenResponse({ component: FEATURE_NAME, token })),
  redirectToLogin: (itemId, featureName, loginType) =>
    dispatch(actionsVis.redirectToLogin({ itemId, featureName, loginType })),
  onScrollComponent: ({ target, focus }) => dispatch(actionsVis.onScrollComponent({ component: target, focus })),
  showSnackbar: props => dispatch(actionsVip.showSnackbar({ ...props })),
});

export { mapStateToProps, mapDispatchToProps };
