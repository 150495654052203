import React from 'react';
import { shape, string } from 'prop-types';
import StyledLabel from '../styled-label';
import IconFactory from '../icons/factory-builder';

const Item = ({ title, subtitle, itemClass, titleClass, subtitleClass }) => (
  <div className={itemClass} data-testid="item">
    <div className={titleClass} data-testid="title">
      <StyledLabel as="span" {...title} />
    </div>
    <div className={subtitleClass} data-testid="subtitle">
      {IconFactory(subtitle.icon)}
      <StyledLabel as="span" {...subtitle.label} />
    </div>
  </div>
);

Item.propTypes = {
  title: shape({}).isRequired,
  subtitle: shape({
    label: shape({}),
    icon: shape({}),
  }).isRequired,
  itemClass: string,
  titleClass: string,
  subtitleClass: string,
};

Item.defaultProps = {
  itemClass: null,
  titleClass: null,
  subtitleClass: null,
};

export default Item;
