import { googleClientId, googleMapChannel } from './variables';

const drawMap = (mapInfo, element, showInfoWindow) => {
  const { google } = window;
  const center = { lat: parseFloat(mapInfo.location.latitude), lng: parseFloat(mapInfo.location.longitude) };
  // eslint-disable-next-line n/global-require
  const pinRE = require('../../assets/images/pin-real-estate.svg');

  const map = new google.maps.Map(document.getElementById(element), {
    center,
    zoom: parseInt(mapInfo.zoom, 10),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
  });

  const marker = new google.maps.Marker({
    position: center,
    animation: google.maps.Animation.DROP,
    icon: pinRE,
    map,
  });

  if (!showInfoWindow) {
    return;
  }

  const contentWindow = `<div class="tooltip-title">${mapInfo.item_address}</div><div>${mapInfo.item_location}</div>`;

  const infoWindow = new google.maps.InfoWindow({
    content: contentWindow,
    maxWidth: 208,
    pixelOffset: new google.maps.Size(0, -8),
  });

  infoWindow.open(map, marker);
};

const renderMap = (mapInfo, element, showInfoWindow) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://maps.googleapis.com/maps/api/js?client=${googleClientId}&channel=${googleMapChannel}`;

  script.onload = () => {
    drawMap(mapInfo, element, showInfoWindow);
  };

  document.body.appendChild(script);
};

export { renderMap, drawMap };
