import React, { useState, useContext, useEffect } from 'react';
import { arrayOf, bool, shape, string, func, oneOf, number, oneOfType } from 'prop-types';
import { getForm, saveForm } from '../../../../form/local-storage/unregistered-quotation';
import { validate, formatRut } from '../../../../form/validations/unregistered-quotation-validations';
import StaticPropsContext from '../../../context/static-props';
import UnregisteredFormDesktop from '../desktop/unregistered-form.desktop';
import UnregisteredFormMobile from '../mobile/unregistered-form.mobile';

const localStorageKey = 'unregistered-quotation';

const UnregisteredForm = ({
  form,
  onSubmit,
  updateForm,
  url,
  messages,
  recaptchaPublicKey,
  stepBack,
  privacyDisclaimer,
  selectedUnit,
  selectedModel,
  isPostingQuotation,
  model_label,
  unit_label,
  selection_info,
}) => {
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState(null);
  const [lgpdConfirmationChecked, setLgpdConfirmationChecked] = useState(false);
  const [hasError, setHasError] = useState({});
  const { deviceType } = useContext(StaticPropsContext);
  const UnregisteredFormComponent = deviceType === 'mobile' ? UnregisteredFormMobile : UnregisteredFormDesktop;

  useEffect(() => {
    const storedValues = getForm(localStorageKey);
    const newValues = form;
    const errors = {};
    Object.entries(storedValues).forEach(([field, value]) => {
      if (value !== '' && form[field] && (form[field].value === undefined || form[field].value === '')) {
        newValues[field].value = value;
      }
      if (newValues[field].has_validation_error) {
        errors[field] = true;
      }
    });
    updateForm(newValues);
    setHasError(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errors = {};
    Object.entries(form).forEach(([field, value]) => {
      if (value.has_validation_error) {
        errors[field] = true;
      }
    });
    setHasError(errors);
  }, [form]);

  const onInputChange = e => {
    const field = e.target.name;
    let { value } = e.target;

    if (field === 'identification_number') {
      const rut = String(value)
        .trim()
        .replace(/[.|-]/g, '');
      value = rut.length > 1 ? `${rut.slice(0, -1)}-${rut.slice(-1).toLocaleLowerCase()}` : value;
    }

    updateForm({
      ...form,
      [field]: {
        ...form[field],
        value,
      },
    });
  };

  const onBlur = e => {
    const { name: field } = e.target;
    const formInput = form[field];

    const error = formInput.validations ? validate(formInput.value, formInput.validations) : null;

    updateForm({
      ...form,
      [field]: {
        ...form[field],
        error,
        has_validation_error: !!error,
      },
    });
    setHasError({ ...hasError, [field]: !!error });
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    let phoneField = null;

    if (form.phone.value) {
      phoneField = form.phone.value.trim();
    }

    const trimedValues = {
      ...form,
      name: { ...form.name, value: form.name.value.trim() },
      last_name: { ...form.last_name, value: form.last_name.value.trim() },
      email: { ...form.email, value: form.email.value.trim() },
      phone: { ...form.phone, value: phoneField },
      identification_number: {
        ...form.identification_number,
        value: formatRut(form.identification_number.value),
      },
    };

    saveForm(localStorageKey, trimedValues, ['name', 'last_name', 'email', 'phone', 'identification_number']);

    onSubmit({
      filledForm: {
        name: trimedValues.name.value,
        last_name: trimedValues.last_name.value,
        email: trimedValues.email.value,
        identification_number: trimedValues.identification_number.value,
        phone: trimedValues.phone.value,
      },
      recaptchaTokenV2,
    });
  };

  const lgpdConfirmationToggle = e => {
    setLgpdConfirmationChecked(e.target.checked);
  };

  return (
    <UnregisteredFormComponent
      form={form}
      lgpd_confirmation={privacyDisclaimer}
      messages={messages}
      recaptchaPublicKey={recaptchaPublicKey}
      url={url}
      isPostingQuotation={isPostingQuotation}
      stepBack={stepBack}
      selectedUnit={selectedUnit}
      selection_info={selection_info}
      selectedModel={selectedModel}
      onInputChange={onInputChange}
      onBlur={onBlur}
      lgpdConfirmationToggle={lgpdConfirmationToggle}
      handleOnSubmit={handleOnSubmit}
      lgpdConfirmationChecked={lgpdConfirmationChecked}
      setRecaptchaTokenV2={setRecaptchaTokenV2}
      recaptchaTokenV2={recaptchaTokenV2}
      hasError={hasError}
      model_label={model_label}
      unit_label={unit_label}
    />
  );
};

UnregisteredForm.propTypes = {
  form: shape({
    confirm: shape({
      label: shape({
        text: string.isRequired,
      }),
    }).isRequired,
    name: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    last_name: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([number, bool]),
          error_message: string,
        }),
      ),
    }),
    email: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    phone: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: string,
          error_message: string,
        }),
      ),
    }),
    identification_number: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
  }).isRequired,
  url: string,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
    form: shape({
      title: shape({ text: string.isRequired }),
      subtitles: arrayOf(
        shape({
          text: string.isRequired,
          icon: shape({
            id: string,
            color: string,
          }),
        }),
      ),
      conditions: shape({
        text: string.isRequired,
        values: shape(),
      }),
      confirm: shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
        item_id: string.isRequired,
      }).isRequired,
      name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      last_name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      email: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      phone: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      identification_number: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
    }),
    collapsible_info: shape({
      edit_link: shape({
        text: string.isRequired,
      }),
    }),
  }),
  privacyDisclaimer: shape({
    text: string.isRequired,
    values: shape({}),
  }).isRequired,
  recaptchaPublicKey: string,
  onSubmit: func.isRequired,
  stepBack: func,
  updateForm: func.isRequired,
  selectedUnit: shape({}).isRequired,
  selectedModel: shape({}).isRequired,
  isPostingQuotation: bool,
  model_label: shape({
    color: string,
    font_family: string,
    font_size: string,
    text: string,
  }),
  unit_label: shape({
    color: string,
    font_family: string,
    font_size: string,
    text: string,
  }),
  selection_info: shape({
    title: shape({
      text: string,
    }),
    collapsible_info: shape({
      icon: shape({
        id: string,
      }),
    }),
  }),
};

UnregisteredForm.defaultProps = {
  className: null,
  url: null,
  messages: null,
  recaptchaPublicKey: null,
  stepBack: null,
  isPostingQuotation: false,
  model_label: null,
  unit_label: null,
  selection_info: null,
};

export default UnregisteredForm;
