import React, { useEffect } from 'react';
import { shape, string, func, number } from 'prop-types';
import classnames from 'classnames';
import MultifamilyModalContent from './multifamily-modal-content';

const namespace = 'ui-pdp-mf-modal';

const Multifamily = ({
  className,
  // eslint-disable-next-line react/prop-types
  form,
  // eslint-disable-next-line react/prop-types
  messages,
  resizeModal,
  modelCarouselSelected,
  currentState,
  updateCurrentState,
  track,
  ...rest
}) => {
  const selectedUnit = currentState?.selectedUnit || null;
  const { id: defaultSelectedModelId = null } = rest.models[0] || {};
  const selectedModelId = currentState?.selectedModelId || defaultSelectedModelId;

  const handleUpdateModelOnCurrentState = modelId => {
    updateCurrentState({
      ...currentState,
      selectedModelId: modelId,
      isFetching: true,
    });
  };

  const handleUpdateUnitOnCurrentState = unit => {
    updateCurrentState({
      ...currentState,
      selectedUnit: unit,
    });
  };

  useEffect(() => {
    if (resizeModal) {
      resizeModal();
    }
  });

  return (
    <div className={classnames(namespace, className)}>
      <MultifamilyModalContent
        onChangeUnit={handleUpdateUnitOnCurrentState}
        onChangeModel={handleUpdateModelOnCurrentState}
        selectedUnit={selectedUnit}
        selectedModelId={selectedModelId}
        isFetching={currentState?.isFetching || selectedUnit == null}
        {...rest}
      />
    </div>
  );
};

Multifamily.propTypes = {
  className: string,
  track: shape({}),
  resizeModal: func.isRequired,
  modelCarouselSelected: number,
  models: MultifamilyModalContent.propTypes.models.isRequired,
  currentState: shape({}),
  updateCurrentState: func.isRequired,
};

Multifamily.defaultProps = {
  className: null,
  messages: null,
  track: null,
  modelCarouselSelected: null,
  currentState: {},
};

export default React.memo(Multifamily);
