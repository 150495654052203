import React, { useEffect } from 'react';
import { arrayOf, shape, func, number, string, bool } from 'prop-types';
import { Button } from '@andes/button';
import Dropdown, { DropdownItem } from '@andes/dropdown';
import { Tabs, Tab, TabContent } from '@andes/tabs';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';
import StyledLabel from '../styled-label';
import Image from '../image';
import HighlightedSpecsRes from '../highlighted-specs-res';
import { trackPage, trackEvent } from '../../lib/tracking';

const namespace = 'ui-vip-multifamily-modal';

const MultifamilyModalContent = ({
  models,
  modelUnits,
  header,
  subtitle,
  fullAddress,
  getUnitsLTR,
  unitListTitle,
  onChangeUnit,
  onChangeModel,
  selectedUnit,
  selectedModelId,
  track,
  isFetching,
}) => {
  const selectedTab = models.findIndex(model => model.id === selectedModelId);

  const currentModelUnits = modelUnits[selectedModelId];

  const handleTabClick = modelId => {
    if (selectedModelId !== modelId) {
      onChangeModel(modelId);
      getUnitsLTR({
        modelId,
        deviceType: 'desktop',
      });
    }
  };

  const handleChangeUnit = (e, unitId) => {
    e.preventDefault();
    const unit = currentModelUnits.find(modelUnit => unitId === modelUnit.id);

    onChangeUnit(unit);
  };

  const handleActionClick = (e, action) => {
    e.preventDefault();
    if (action.track) {
      trackEvent(action.track);
    }
    window.location.href = action.target;
  };

  useEffect(() => {
    trackPage(track);
  }, [track]);

  const tabTitle = modelIndex => {
    const model = models[parseInt(modelIndex, 10)];
    return (
      <div className={`${namespace}__tab-title`}>
        <div className={`${namespace}__tab-title-picture-container`}>
          <Image
            className={`${namespace}__tab-title-picture`}
            key={model.id}
            id={model.picture.id}
            src={model.picture.thumbnail.src}
            alt={model.picture.alt}
            onload="on"
          />
        </div>
        <div className={`${namespace}__tab-title-attrs`}>
          {model.name && (
            <div className={`${namespace}__tab-name-container`}>
              <StyledLabel className={`${namespace}__tab-name`} key={model.name.text} as="span" {...model.name} />
            </div>
          )}
          {model.attributes.map(attribute => (
            <StyledLabel key={attribute.label.text} as="span" {...attribute.label} />
          ))}
        </div>
      </div>
    );
  };

  const hasModels = modelUnits && currentModelUnits;

  return (
    <div className={namespace}>
      <div className={`${namespace}__header`}>
        <div className={`${namespace}__title`}>
          <StyledLabel as="h1" {...header} />
          <StyledLabel as="h2" {...subtitle} />
        </div>
        <div className={`${namespace}__subtitle`}>
          <StyledLabel as="span" {...fullAddress} />
        </div>
      </div>
      <div className={`${namespace}__models`}>
        {models.length > 0 && (
          <div className={`${namespace}__tabs`}>
            <Tabs selected={selectedTab}>
              {models.map((model, index) => (
                <Tab
                  key={model.id}
                  title={tabTitle(index)}
                  tabIndex={model.id}
                  onClick={() => handleTabClick(model.id)}
                  selected={selectedModelId === model.id}
                >
                  <TabContent id={model.id}>
                    {isFetching ? (
                      <div className={`${namespace}__spinner-container`}>
                        <ProgressIndicatorCircular modifier="block" size="large" />
                      </div>
                    ) : (
                      <>
                        <div className={`${namespace}__tab-left-column`}>
                          {selectedUnit && (
                            <div className={`${namespace}__tab-picture-container`}>
                              <Image
                                className={`${namespace}__tab-picture`}
                                key={selectedUnit.id}
                                id={selectedUnit.picture.id}
                                src={selectedUnit.picture.src}
                                alt={model.picture.alt}
                                onload="on"
                              />
                            </div>
                          )}
                        </div>
                        <div className={`${namespace}__tab-right-column`}>
                          <div>
                            <div className={`${namespace}__selector-container`}>
                              {hasModels && currentModelUnits.length > 1 && (
                                <Dropdown
                                  className={`${namespace}__unit-selector`}
                                  menuAlignment="bottom"
                                  onChange={handleChangeUnit}
                                  value={selectedUnit ? selectedUnit.id : null}
                                  label={unitListTitle.text}
                                  key={`dropdown--${selectedModelId}`}
                                  type="form"
                                  menuMaxHeight={320}
                                >
                                  {currentModelUnits.map(unit => (
                                    <DropdownItem
                                      key={unit.id}
                                      title={unit.name?.text}
                                      description={unit.description?.text}
                                      rightContent={unit.price?.text}
                                      value={unit.id}
                                    />
                                  ))}
                                </Dropdown>
                              )}
                            </div>
                            {selectedUnit?.price && (
                              <div className={`${namespace}__pricing`}>
                                <StyledLabel as="p" {...selectedUnit.price} />
                                <StyledLabel as="p" {...selectedUnit.maintenance_fee} />
                              </div>
                            )}
                            {selectedUnit && (
                              <HighlightedSpecsRes
                                specs={selectedUnit.attributes}
                                className={`${namespace}__highlighted-specs`}
                              />
                            )}
                          </div>
                          {selectedUnit && (
                            <div>
                              {selectedUnit.availability && (
                                <StyledLabel
                                  as="p"
                                  {...selectedUnit.availability}
                                  className={`${namespace}__availability`}
                                />
                              )}
                              {selectedUnit.actions?.length > 0 && (
                                <div className={`${namespace}__button-container-multiple`}>
                                  {selectedUnit.actions.map((action, iterator) => (
                                    <Button
                                      key={action.label.text}
                                      data-testid={`submit-step-${iterator}`}
                                      className={`${namespace}__button`}
                                      onClick={e => handleActionClick(e, action)}
                                      disabled={action.disabled}
                                      hierarchy={action.type === 'primary' ? 'loud' : 'quiet'}
                                      href={action.target}
                                      {...selectedUnit.action}
                                    >
                                      {action.label.text}
                                    </Button>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </TabContent>
                </Tab>
              ))}
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
};

MultifamilyModalContent.propTypes = {
  models: arrayOf(
    shape({
      id: number.isRequired,
      picture: shape({
        alt: string,
        thumbnail: shape({}).isRequired,
      }).isRequired,
      attributes: arrayOf(
        shape({
          icon: shape({
            id: string,
            color: string,
            size: string,
          }).isRequired,
          label: shape({}).isRequired,
        }),
      ),
    }),
  ).isRequired,
  modelUnits: shape({}).isRequired,
  header: shape({
    text: string.isRequired,
  }).isRequired,
  subtitle: shape({
    text: string.isRequired,
  }).isRequired,
  fullAddress: shape({
    text: string.isRequired,
  }).isRequired,
  getUnitsLTR: func.isRequired,
  modelCarouselSelected: number,
  unitListTitle: shape({}),
  onChangeUnit: func.isRequired,
  onChangeModel: func.isRequired,
  selectedUnit: shape({}),
  selectedModelId: number,
  track: shape({}),
  isFetching: bool,
};

MultifamilyModalContent.defaultProps = {
  modelCarouselSelected: null,
  unitListTitle: {},
  selectedModelId: null,
  selectedUnit: null,
  track: {},
  isFetching: false,
};

export default MultifamilyModalContent;
