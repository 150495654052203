const clipMultimediaString = ({ id, thumbnail }, className = '') => `
    <section class="clip-wrapper ${className}">
      <div class="clip-wrapper__glass-screen"></div>
      <img class="clip-wrapper__thumbnail" src="${thumbnail?.src}" alt="${thumbnail?.alt}"></img>
      <i class="clip-wrapper__play"></i>
      <i class="clip-wrapper__pause clip-wrapper__pause--hide"></i>
      <i class="clip-wrapper__big-mute clip-wrapper__big-mute--hide"></i>
      <i class="clip-wrapper__spinner clip-wrapper__spinner--hide"></i>
      <i class="clip-wrapper__muted clip-wrapper__muted--hide"></i>
      <i class="clip-wrapper__unmuted clip-wrapper__unmuted--hide"></i>
      <i class="clip-wrapper__exit-fullscreen clip-wrapper__exit-fullscreen--hide"></i>
      <div class="clip-wrapper__shadow clip-wrapper__shadow--hide"></div>
      <div class="player-fullscreen" id="player-fullscreen-${id}"></div>
    </section>
  `;

export default clipMultimediaString;
