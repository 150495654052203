import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import { mapStateToProps, mapDispatchToProps } from './questions-vis-commons';

const FEATURE_NAME = 'questions_short_description';

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps(FEATURE_NAME),
    mapDispatchToProps(FEATURE_NAME),
  ),
);

const component = Component => enhance(Component);

export default component;
