import React from 'react';
import { shape } from 'prop-types';
import StoreHeader from '../../features/store-header/store-header';
import Gallery from '../../features/gallery/gallery.desktop';
import Onboarding from '../../features/onboarding/onboarding.desktop';

// Head Section
import ItemStatusHead from '../../features/item-status/head';

import RedirectFromStatus from '../../features/item-status/redirected-from';
import GroupedHeader from './sections/grouped-header';
import KYCNotification from '../../features/kyc-notification/kyc-notification.desktop';

// Short Description
import Bookmark from '../../features/bookmark';

import Header from '../../features/header/header.desktop';
import Price from '../../features/price/price.desktop';
import ItemStatusShortDescription from '../../features/item-status/short-description';
import HighlightedSpecsRes from '../../features/highlighted-specs-res';
import QuestionsShortDescription from '../../features/questions-vis/questions-vis-short-description.desktop';
import QuotationLimitMessage from '../../features/quotation-message/quotation-limit-message';
import QuotationSellerMessage from '../../features/quotation-message/quotation-seller-message';
import GroupedMainActions from '../../features/grouped-main-actions';
import ReportProblem from '../../features/report-problem/report-problem.desktop';
import LabelRentalType from '../../features/label-rental-type';
import MaintenanceFeeLTR from '../../features/maintenance-feel-ltr/maintenance-fee-ltr';
import AvailableUnitsLabel from '../../features/available-units-label/available-units-label';
import OnlineVisitRequestLabel from '../../features/online-visit-request-label/online-visit-request-label';
import SellerGoodAttention from '../../features/seller-good-attention/seller-good-attention';

// Content Left
import ModelsCarouselDesktop from '../../features/models-carousel/models-carousel.desktop';
import MultifamilyModelsCarousel from '../../features/mf-models-carousel/mf-models-carousel.desktop';
import AvailableUnits from '../../features/available-units/available-units.desktop';
import Location from '../../features/location/location.desktop';
import TechnicalSpecifications from '../../features/technical-specifications/technical-specifications.desktop';
import HighlightedSpecsAttrs from '../../features/highlighted-specs/highlighted-specs-attrs.desktop';
import Description from '../../features/description/description.desktop';
import WhatsappCard from '../../features/whatsapp-card/whatsapp-card';
import RequirementsCardLTR from '../../features/requirements-card-ltr/requirements-card-ltr.desktop';
import RequirementsCardLTRNew from '../../features/requirements-card-ltr-new/requirements-card-ltr.desktop';
import Questions from '../../features/questions-vis/questions-vis.desktop';
import QuestionsUnregistered from '../../features/questions/questions-unregistered-vip';
import PointsOfInterest from '../../features/points-of-interest/points-of-interest.desktop';

// Content Right
import StickyWrapper from '../../features/sticky-wrapper';

import PriceComparison from '../../features/price-comparison';
import PriceComparisonRex from '../../features/price-comparison-rex';
import ExternalCredits from '../../features/external-credits';
import SecurityTips from '../../features/security-tips/security-tips.desktop';
import RelatedServices from '../../features/related-services';
import SellerProfile from '../../features/seller-profile/seller-profile';
import OnlineRentalDetails from '../../features/online-rental-details/online-rental-details.desktop';
import Snackbar from '../../features/snackbar';

// New Experiencia REX
import SellerProfileREX from '../../features/seller-profile/seller-profile-REX';
import LocationAndPoints from '../../features/location-and-points/location-and-points.desktop';
import HighlightedSpecsRex from '../../features/highlighted-specs-rex/highlighted-specs-rex.desktop';
import DescriptionREX from '../../features/description/description-rex.desktop';

// Content Bottom
import Denounce from '../../features/denounce';

import BillboardAd from '../../features/billboard-ad';
import PiStatistics from '../../features/pi-statistics/pi-statistics';
import BillboardAdn from '../../features/billboard-adn/billboard-adn-bottom';

// Recommendations
import RecommendationsCarouselSnapped from '../../features/recommendations/carousel-snapped';

// General
import View from '../view';

import GroupedShareBookmark from '../../features/grouped-share-bookmark';
import GiftRegistry from '../../features/gift-registry';
import Grid from '../../../../components/grid';

const RealEstateDesktop = props => (
  <View {...props} vertical="real-estate">
    <Onboarding />
    <Grid.Container className="ui-pdp-container--top">
      <Grid.Row>
        <Grid.Col className="ui-vip-core-container--head">
          <RedirectFromStatus />
          <GroupedHeader />
          <ItemStatusHead />
          <RecommendationsCarouselSnapped
            featureName="carousel_classifieds_res_vip_paused"
            className="mb-24 mt-24"
            lazy={false}
            hasDynamicSlidesPerView
            preload
            fetchPriority="high"
            criticalImages={5}
          />
          <RecommendationsCarouselSnapped
            featureName="carousel_classifieds_res_ltr_vip_paused"
            className="mb-24 mt-24"
            lazy={false}
            hasDynamicSlidesPerView
            preload
            fetchPriority="high"
            criticalImages={5}
          />
          <KYCNotification />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
    <Grid.Container>
      <StoreHeader />
    </Grid.Container>
    <Grid.Container className="ui-pdp-container--pdp">
      <Grid.Row id="ui-pdp-main-container" className="ui-pdp-container__row--reverse ui-pdp--relative  pb-40">
        <Grid.Col className="ui-pdp-container--column-right mt-20 mr-20">
          <StickyWrapper columnName="right">
            <Grid.Row className="ui-pdp-component-list">
              <Grid.Col
                size={2}
                className="ui-vip-core-container--short-description ui-vip-core-container--column__right"
              >
                <Header>
                  <Bookmark showLabel={false} />
                </Header>
                <Price />
                <MaintenanceFeeLTR />
                <LabelRentalType />
                <HighlightedSpecsRes />
                <AvailableUnitsLabel />
                <ItemStatusShortDescription />
                <QuotationLimitMessage />
                <QuotationSellerMessage />
                <QuestionsShortDescription enableRedirectAction />
                <OnlineVisitRequestLabel />
                <GroupedMainActions />
                <SellerGoodAttention />
                <ReportProblem />
                <GiftRegistry className="mt-24" />
                <GroupedShareBookmark className="mt-24" showOnlyForWishlistVariant />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="ui-pdp-component-list ui-vip-content-right mt-16">
              <SellerProfileREX />
              <PriceComparison />
              <SellerProfile />
              <PriceComparisonRex />
              <ExternalCredits />
              <OnlineRentalDetails />
              <RelatedServices />
              <SecurityTips />
            </Grid.Row>
          </StickyWrapper>
        </Grid.Col>
        <Grid.Col className="ui-pdp-container--column-left pb-40" size={2}>
          <Grid.Row>
            <Grid.Col size={2}>
              <Gallery />
            </Grid.Col>
          </Grid.Row>
          <Grid.Col className="ui-vip-core-container--content-left">
            <ModelsCarouselDesktop />
            <MultifamilyModelsCarousel />
            <AvailableUnits />
            <HighlightedSpecsRex className="pl-0 pr-0" />
            <RequirementsCardLTRNew />
            <Location />
            <HighlightedSpecsAttrs className="pl-0 pr-0" />
            <TechnicalSpecifications />
            <Description />
            <RequirementsCardLTR />
            <DescriptionREX />
            <LocationAndPoints />
            <PointsOfInterest />
            <WhatsappCard />
            <Questions enableRedirectAction />
            <QuestionsUnregistered className="ui-pdp-questions-unregistered__desktop" />
          </Grid.Col>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
    <Grid.Container className="ui-vip-core ui-pdp-container--bottom">
      <Denounce />
      <RecommendationsCarouselSnapped
        featureName="carousel_classifieds_res_vip"
        className="mb-64"
        hasDynamicSlidesPerView
      />
      <BillboardAd />
      <PiStatistics />
      <BillboardAdn />
    </Grid.Container>
    <Snackbar />
  </View>
);

RealEstateDesktop.propTypes = {
  initialState: shape().isRequired,
};

export default RealEstateDesktop;
