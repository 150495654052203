import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import SellerGoodAttentionLabel from '../../../../components/seller-good-attention/seller-good-attention';
import { onScrollComponent } from '../../../../actions/vip/vis';

const FEATURE_NAME = 'seller_good_attention';
const MODAL_FEATURE_NAME = 'seller_profile_rex';

const mapStateToProps = (
  { components: { [FEATURE_NAME]: sellerGoodAttention, [MODAL_FEATURE_NAME]: sellerProfile } },
  ownProps,
) => {
  const newSellerProfile = {
    phoneLink: sellerProfile.phone_link,
    extraInfo: sellerProfile.extra_info,
    sellerName: { ...sellerProfile.seller_name },
    userStatusTooltip: sellerProfile.user_status_tooltip,
    containerActions: sellerProfile.container_actions,
    bottomExtraInfo: sellerProfile.bottom_extra_info,
    tagsReputationAlign: sellerProfile.tags_reputation_align,
    linkLabel: sellerProfile.link_label,
    featureName: MODAL_FEATURE_NAME,
    ...sellerProfile,
  };
  return {
    ...sellerGoodAttention,
    ...ownProps,
    sellerProfile: newSellerProfile,
  };
};

const mapDispatchToProps = dispatch => ({
  onScrollComponent: ({ component, focus }) => dispatch(onScrollComponent({ component, focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(SellerGoodAttentionLabel);
export { mapStateToProps };
