import React from 'react';
import { bool, shape, string, func } from 'prop-types';
import UnregisteredForm from '../commons/unregistered-form';
import UnregisteredPreLogin from '../commons/unregistered-pre-login';

const namespace = 'ui-pdp-unregistered-quotation-no-tabs';

const UnregisteredWithoutTabsMobile = ({
  form,
  selection_info,
  selectedUnit,
  selectedModel,
  updateForm,
  onSubmit,
  messages,
  recaptchaPublicKey,
  setRecaptchaTokenV2,
  url,
  className,
  isPostingQuotation,
  stepBack,
  preLogin,
  preUnregistered,
  onRemove,
  handleOnSubmit,
  chooseStep,
  replaceText,
  step,
  removeForm,
  privacyDisclaimer,
}) => {
  const views = {
    prelogin: (
      <UnregisteredPreLogin
        showUnregistered={() => chooseStep()}
        namespace="ui-pdp-unregistered-pre-login"
        {...preLogin}
      />
    ),
    unregisteredShield: (
      <UnregisteredPreLogin
        stepBack={stepBack}
        selection_info={selection_info}
        selectedModel={selectedModel}
        selectedUnit={selectedUnit}
        showUnregistered={() => removeForm()}
        replaceText={replaceText}
        onRemove={onRemove}
        handleOnSubmit={handleOnSubmit}
        isUnregistered
        namespace="ui-pdp-pre-unregistered"
        url={url}
        recaptchaPublicKey={recaptchaPublicKey}
        setRecaptchaTokenV2={setRecaptchaTokenV2}
        {...preUnregistered}
      />
    ),
    unregisteredAccess: (
      <div className={namespace}>
        <UnregisteredForm
          updateForm={updateForm}
          onSubmit={onSubmit}
          messages={messages}
          recaptchaPublicKey={recaptchaPublicKey}
          url={url}
          className={className}
          isPostingQuotation={isPostingQuotation}
          selection_info={selection_info}
          selectedModel={selectedModel}
          selectedUnit={selectedUnit}
          stepBack={stepBack}
          form={form}
          preLogin={preLogin}
          privacyDisclaimer={privacyDisclaimer}
        />
      </div>
    ),
  };
  return <>{views[step]}</>;
};

UnregisteredWithoutTabsMobile.propTypes = {
  selection_info: shape({
    title: shape({
      text: string,
    }),
    collapsible_info: shape({
      icon: shape({
        id: string,
      }),
    }),
  }),
  selectedUnit: shape({
    name: shape({
      text: string,
    }),
  }),
  selectedModel: shape({}),
  updateForm: func.isRequired,
  onSubmit: func.isRequired,
  messages: shape({}),
  recaptchaPublicKey: string,
  url: string,
  className: string,
  isPostingQuotation: bool,
  redirectToLogin: func,
  stepBack: func,
  model_label: shape({}),
  unit_label: shape({}),
  setRecaptchaTokenV2: func,
  preLogin: shape({}),
  preUnregistered: shape({}),
  onRemove: func,
  handleOnSubmit: func,
  replaceText: shape({}),
  step: string,
  removeForm: func,
  chooseStep: func,
  form: shape({}),
  privacyDisclaimer: shape({}),
};

UnregisteredWithoutTabsMobile.defaultProps = {
  selection_info: null,
  selectedUnit: null,
  selectedModel: null,
  messages: null,
  recaptchaPublicKey: null,
  url: null,
  className: null,
  isPostingQuotation: false,
  redirectToLogin: null,
  stepBack: null,
  model_label: null,
  unit_label: null,
  privacyDisclaimer: null,
};

export default UnregisteredWithoutTabsMobile;
