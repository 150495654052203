import React from 'react';
import { arrayOf, bool, shape, string, func, oneOf, number, oneOfType } from 'prop-types';
import classnames from 'classnames';
import AndesForm from '@andes/form';
import { Button, ProgressButton, ButtonText } from '@andes/button';
import TextField from '@andes/textfield';
import Checkbox from '@andes/checkbox';
import Snackbar from '@andes/snackbar';

import StyledLabel from '../../../styled-label';
import { Recaptchav2 } from '../../../recaptcha';
import { getMaxLength } from '../../../../form/validations/unregistered-quotation-validations';
import componentEnhance from '../../../../lib/component-enhance';
import { validateSnackbarColor } from '../../../../utils/validators';

const DEFAULT_DELAY_TIME = 3000;
const namespace = 'ui-pdp-unregistered-quotation-form';

const UnregisteredFormDesktop = ({
  form,
  lgpd_confirmation,
  messages,
  recaptchaPublicKey,
  url,
  isPostingQuotation,
  stepBack,
  selectedUnit,
  selection_info,
  selectedModel,
  onInputChange,
  onBlur,
  lgpdConfirmationToggle,
  handleOnSubmit,
  lgpdConfirmationChecked,
  setRecaptchaTokenV2,
  recaptchaTokenV2,
  model_label,
  unit_label,
  hasError,
}) => {
  const { collapsible_info } = form;

  return (
    <>
      <div className={classnames(namespace, { [`${namespace}--ff-off`]: !selection_info })}>
        <h4 className={`${namespace}__title`}>{form.subtitles[0]?.title?.text}</h4>
        <AndesForm className={classnames(`${namespace}__form`)} action={url} method="POST" onSubmit={handleOnSubmit}>
          <div className={`${namespace}__content`}>
            <>
              <TextField
                name="name"
                data-testid="name-input"
                className={`${namespace}__input`}
                label={form.name.label}
                placeholder={form.name.placeholder}
                autoComplete="off"
                modifier={form.name.error && 'error'}
                helper={form.name.error}
                messageShow={!!form.name.error}
                value={form.name.value}
                onChange={onInputChange}
                onBlur={onBlur}
                maxLength={getMaxLength(form.name.validations)}
              />
              <TextField
                name="last_name"
                data-testid="lastName-input"
                className={`${namespace}__input`}
                label={form.last_name.label}
                placeholder={form.last_name.placeholder}
                autoComplete="off"
                modifier={form.last_name.error && 'error'}
                helper={form.last_name.error}
                value={form.last_name.value}
                onChange={onInputChange}
                onBlur={onBlur}
                maxLength={getMaxLength(form.last_name.validations)}
              />
              <TextField
                name="phone"
                data-testid="phone-input"
                className={`${namespace}__input`}
                label={form.phone.label}
                placeholder={form.phone.placeholder}
                autoComplete="off"
                type="number"
                value={form.phone.value}
                onChange={onInputChange}
                onBlur={onBlur}
                helper={form.phone.error || form.phone.message}
                modifier={form.phone.error && 'error'}
                maxLength={getMaxLength(form.phone.validations)}
              />
              <TextField
                name="email"
                data-testid="email-input"
                className={`${namespace}__input`}
                label={form.email.label}
                placeholder={form.email.placeholder}
                autoComplete="off"
                type="email"
                helper={form.email.error}
                messageShow={!!form.email.error}
                value={form.email.value}
                modifier={form.email.error && 'error'}
                onChange={onInputChange}
                onBlur={onBlur}
                maxLength={getMaxLength(form.email.validations)}
              />
              <TextField
                name="identification_number"
                data-testid="identificationNumber-input"
                className={`${namespace}__input`}
                label={form.identification_number.label}
                placeholder={form.identification_number.placeholder}
                autoComplete="off"
                helper={form.identification_number.error || form.identification_number.message}
                messageShow={!!form.identification_number.error || !!form.identification_number.message}
                value={form.identification_number.value}
                modifier={form.identification_number.error && 'error'}
                onChange={onInputChange}
                onBlur={onBlur}
                maxLength={getMaxLength(form.identification_number.validations)}
              />
            </>
          </div>
          {selectedModel && (
            <div className={`${namespace}__collapsible-info`}>
              <div className={`${namespace}__collapsible-info__container`}>
                <StyledLabel as="span" key="modelLabel" {...model_label} />
                <span className={`${namespace}__collapsible-info-model`}>{selectedModel?.picture.alt}</span>
                <StyledLabel as="span" key="unitLabel" {...unit_label} />
                <span className={`${namespace}__collapsible-info-unit`}>{selectedUnit.name.text}</span>
              </div>
              <StyledLabel
                {...collapsible_info?.edit_link}
                as="a"
                className={`${namespace}__collapsible-info__edit`}
                onClick={stepBack}
                data-testid="edit_link"
              />
            </div>
          )}

          {lgpd_confirmation && (
            <div className={`${namespace}__lgpd-confirmation-checkbox__wrapper`}>
              <Checkbox
                label={componentEnhance.jsx(lgpd_confirmation.text, lgpd_confirmation.values)}
                className={`${namespace}__lgpd-confirmation-checkbox`}
                onChange={lgpdConfirmationToggle}
                checked={lgpdConfirmationChecked}
              />
            </div>
          )}
          {recaptchaPublicKey && (
            <Recaptchav2
              align="left"
              className={`${namespace}-recaptcha`}
              recaptchaPublicKey={recaptchaPublicKey}
              required
              lowEnd
              id="unregistered-quotation-recaptcha"
              verifyCallback={token => setRecaptchaTokenV2(token)}
            />
          )}
          <div className={`${namespace}__footer`}>
            <ProgressButton
              data-testid="quotation-button"
              className={`${namespace}__button`}
              hierarchy="loud"
              type="submit"
              disabled={
                Object.values(hasError).some(element => element) ||
                !lgpdConfirmationChecked ||
                (recaptchaPublicKey && !recaptchaTokenV2)
              }
              loading={isPostingQuotation}
              progressLabel={form.confirm.label.text}
              size="large"
            >
              <ButtonText>{form.confirm.label.text}</ButtonText>
            </ProgressButton>
            {form.cancel && (
              <Button className={`${namespace}__actions__login`} href={form.cancel.target} hierarchy="transparent">
                {form.cancel.label.text}
              </Button>
            )}
          </div>
        </AndesForm>
        {messages && messages.text && (
          <Snackbar
            delay={DEFAULT_DELAY_TIME}
            message={messages.text}
            color={validateSnackbarColor(messages.type)}
            show
            key="unregistered-quotation-snackbar"
          />
        )}
      </div>
    </>
  );
};

UnregisteredFormDesktop.propTypes = {
  className: string,
  form: shape({
    confirm: shape({
      label: shape({
        text: string.isRequired,
      }),
    }).isRequired,
    name: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    last_name: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([number, bool]),
          error_message: string,
        }),
      ),
    }),
    email: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    phone: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: string,
          error_message: string,
        }),
      ),
    }),
    identification_number: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
  }).isRequired,
  url: string,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
    form: shape({
      title: shape({ text: string.isRequired }),
      subtitles: arrayOf(
        shape({
          text: string.isRequired,
          icon: shape({
            id: string,
            color: string,
          }),
        }),
      ),
      conditions: shape({
        text: string.isRequired,
        values: shape(),
      }),
      confirm: shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
        item_id: string.isRequired,
      }).isRequired,
      name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      last_name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      email: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      phone: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      identification_number: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
    }),
    collapsible_info: shape({
      edit_link: shape({
        text: string.isRequired,
      }),
    }),
  }),
  lgpd_confirmation: shape({
    text: string.isRequired,
    values: shape({}),
  }).isRequired,
  recaptchaPublicKey: string,
  onSubmit: func.isRequired,
  stepBack: func,
  updateForm: func.isRequired,
  selectedUnit: shape({}).isRequired,
  selectedModel: shape({}).isRequired,
  isPostingQuotation: bool,
  model_label: shape({
    color: string,
    font_family: string,
    font_size: string,
    text: string,
  }),
  unit_label: shape({
    color: string,
    font_family: string,
    font_size: string,
    text: string,
  }),
  selection_info: shape({
    title: shape({
      text: string,
    }),
    collapsible_info: shape({
      icon: shape({
        id: string,
      }),
    }),
  }),
  onInputChange: func.isRequired,
  onBlur: func.isRequired,
  lgpdConfirmationToggle: func.isRequired,
  handleOnSubmit: func.isRequired,
  lgpdConfirmationChecked: bool,
  setRecaptchaTokenV2: func.isRequired,
  recaptchaTokenV2: string,
  hasError: bool,
};

UnregisteredFormDesktop.defaultProps = {
  className: null,
  url: null,
  messages: null,
  recaptchaPublicKey: null,
  stepBack: null,
  isPostingQuotation: false,
  model_label: null,
  unit_label: null,
  selection_info: null,
  lgpdConfirmationChecked: false,
  recaptchaTokenV: null,
};

export default React.memo(UnregisteredFormDesktop);
