import get from 'lodash/get';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import RelatedServices from '../../../../components/advertising';

const FEATURE_NAME = 'related_services';
const className = 'ui-related-services';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: {
        google_ad: googleAd,
        slot_id: slotId,
        segmentation,
        title,
        viewport_margin,
        personal_data_ads_denied,
        load_second_ad,
        track,
      },
    },
    userConsentCookie,
  },
  ownProps,
) => ({
  slotId,
  title,
  googleAd: {
    ...googleAd,
  },
  segmentation: {
    custID: segmentation ? segmentation.cust_id : '',
    Posiciones: segmentation ? segmentation.position : '',
    platform: segmentation ? segmentation.platform : '',
    CAR_BRAND: segmentation ? segmentation.brand : '',
    Model: segmentation ? segmentation.model : '',
    VEHICLE_YEAR: segmentation ? segmentation.year : '',
    ITEM_CONDITION: segmentation ? segmentation.item_condition : '',
    businessUnit: segmentation ? segmentation.business_unit : '',
  },
  // TO-DO: Remove props when experiment finish
  viewportMargin: viewport_margin,
  personalDataAdsDenied: personal_data_ads_denied,
  className,
  loadSecondAd: load_second_ad,
  melidataEvent: get(track, 'melidata_event', null),
  userConsentCookie,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(RelatedServices);
