// eslint-disable-next-line security/detect-unsafe-regex, no-useless-escape
const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const minLength = 'minLength';
const maxLength = 'maxLength';
const isEmail = 'isEmail';
const isRequired = 'isRequired';
const defaultMaxLength = 120;

const getValidationByType = (validations, type) => (validations ? validations.find(item => item.type === type) : null);

const validationsByDefault = ({ value, validations }) => {
  const minValidation = getValidationByType(validations, minLength);
  const maxValidation = getValidationByType(validations, maxLength);
  const requiredValidation = getValidationByType(validations, isRequired);

  if (value) {
    if (requiredValidation && value.trim().length === 0) {
      return requiredValidation.error_message;
    }
    if (minValidation && value.trim().length < minValidation.value) {
      return minValidation.error_message;
    }
    if (maxValidation && value.trim().length > maxValidation.value) {
      return maxValidation.error_message;
    }

    return null;
  }

  return requiredValidation && requiredValidation.error_message ? requiredValidation.error_message : '';
};

const emailValidations = ({ value, validations }) => {
  const emailValidation = getValidationByType(validations, isEmail);
  const errorMessage = validationsByDefault({
    value,
    validations,
  });
  if (errorMessage) {
    return errorMessage;
  }

  if (!regExp.test(String(value).toLocaleLowerCase())) {
    return emailValidation.error_message;
  }

  return null;
};

const hasError = ({ form, validations }) => {
  const errors = Object.keys(form)
    .map(
      key =>
        validations[key] &&
        validations[key]({
          validations: form[key].validations,
          value: form[key].value,
        }),
    )
    .some(element => element);

  return errors;
};

const hasValidations = ({ form }) =>
  Object.keys(form)
    .map(key => form[key] && form[key].has_validation_error)
    .some(element => element);

const getMaxLength = ({ validations }) => {
  const validation = getValidationByType(validations, maxLength);
  return validation ? validation.value : defaultMaxLength;
};

export { validationsByDefault, emailValidations, hasError, hasValidations, getMaxLength };
