import React, { useState } from 'react';
import { string, shape, func, arrayOf, bool } from 'prop-types';
import classnames from 'classnames';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';
import StyledLabel from '../../styled-label';
import MediaAction from '../../media/media-action';
import { Recaptchav2, Recaptchav3 } from '../../recaptcha';
import Action from '../../buybox/actions/action';
import { trackEvent } from '../../../lib/tracking';

const namespace = 'ui-seller-info__status-info';

const StatusInfo = ({
  className,
  iconClassName,
  titleClassName,
  subtitleClassName,
  icon,
  title,
  subtitles,
  actionLink,
  itemId,
  fetchContactInfo,
  phones,
  isFetchingData,
  redirectToLogin,
  featureName,
  callLink,
  recaptchaPublicKey,
  recaptchaSiteKey,
  contactType,
  textWithAction,
}) => {
  const [recaptchaV2Token, setRecaptchaV2Token] = useState(null);
  const fetchPhones = (isLoginRequired, type) => {
    if (type === 'LINK') {
      return null;
    }
    if (isLoginRequired && redirectToLogin) {
      return redirectToLogin(itemId, featureName, 'vis_motor_contact');
    }
    return (
      fetchContactInfo &&
      fetchContactInfo({
        itemId,
        contactType,
        action: 'classi_contact',
        recaptchaV2Token,
        recaptchaActionType: 'callLink',
        recaptchaSiteKey,
      })
    );
  };

  const onClickHandler = (e, is_login_required, action_type, track) => {
    e.preventDefault();
    if (track && !recaptchaV2Token) {
      trackEvent(track);
    }
    return fetchPhones(is_login_required, action_type);
  };

  const buildPhone = () => {
    if (isFetchingData) {
      const spinner = {
        modifier: 'inline',
      };
      return <ProgressIndicatorCircular {...spinner} />;
    }
    if (phones) {
      return (
        <div className={classnames(`${namespace}__subtitle`, subtitleClassName)}>
          <ul>
            {phones.map(phone => (
              <li key={phone.text}>{phone.text}</li>
            ))}
          </ul>
        </div>
      );
    }
    const link = actionLink || callLink;
    return (
      <div className={`${namespace}-phones`}>
        {recaptchaPublicKey && (
          <Recaptchav2
            recaptchaPublicKey={recaptchaPublicKey}
            required
            lowEnd
            id={`${namespace}-recaptcha`}
            verifyCallback={token => {
              setRecaptchaV2Token(token);
            }}
          />
        )}
        {link && (
          <MediaAction
            label={link.label}
            onClick={e => onClickHandler(e, link.is_login_required, link.action_type, link.track)}
            target={link.target}
          />
        )}
      </div>
    );
  };

  return (
    <div className={classnames(namespace, className)}>
      {icon && (
        <figure aria-hidden="true" className={classnames(`${namespace}__icon`, iconClassName)}>
          {icon}
        </figure>
      )}
      <div>
        <h3 className={classnames(`${namespace}__title`, titleClassName)}>{title}</h3>
        {subtitles &&
          subtitles.map(subtitle => (
            <p key={subtitle.text} className={classnames(`${namespace}__subtitle`, subtitleClassName)}>
              {subtitle.text}
            </p>
          ))}
        {textWithAction && (
          <div className={`${namespace}__subtitle-with-action`}>
            <Action
              itemId={itemId}
              modal_title={textWithAction.modal_title}
              hierarchy="transparent"
              actionType={textWithAction.action_type}
              close_modal_label={textWithAction.close_modal_label}
              label={textWithAction.label.values.blue_text}
              target={textWithAction.target}
              track={textWithAction.track}
              fetchContactInfo={fetchContactInfo}
              recaptchaPublicKey={recaptchaPublicKey}
              recaptchaSiteKey={recaptchaSiteKey}
            />
            <StyledLabel {...textWithAction.label} as="span" />
          </div>
        )}
        {callLink && <div className={classnames(`${namespace}__container-phones`)}>{buildPhone()}</div>}
        {actionLink && <div className={classnames(`${namespace}__container-phones`)}>{buildPhone()}</div>}
      </div>
      {actionLink && <Recaptchav3 siteKey={recaptchaSiteKey} />}
    </div>
  );
};

StatusInfo.propTypes = {
  className: string,
  iconClassName: string,
  titleClassName: string,
  subtitleClassName: string,
  icon: shape({ id: string }),
  title: string,
  subtitles: arrayOf(shape({ text: string })),
  actionLink: shape({ label: shape({ text: string }), is_login_required: bool }),
  itemId: string,
  fetchContactInfo: func,
  phones: arrayOf(
    shape({
      target: string,
      label: shape({
        text: string,
      }),
    }),
  ),
  isFetchingData: bool,
  redirectToLogin: func,
  featureName: string,
  callLink: shape({
    type: string,
    label: shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
    }),
    action_type: string,
    is_login_required: bool,
  }),
  recaptchaPublicKey: string,
  recaptchaSiteKey: string,
  contactType: string,
  textWithAction: shape({
    label: shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
      values: shape({
        blue_text: shape({
          text: string,
        }),
      }),
    }),
    modal_title: string,
    action_type: string,
    close_modal_label: string,
    target: string,
    track: shape({}),
  }),
};

StatusInfo.defaultProps = {
  callLink: null,
  className: null,
  iconClassName: null,
  titleClassName: null,
  subtitleClassName: null,
  icon: null,
  title: null,
  subtitles: null,
  actionLink: null,
  itemId: null,
  fetchContactInfo: null,
  phones: null,
  isFetchingData: null,
  featureName: null,
  recaptchaPublicKey: null,
  recaptchaSiteKey: null,
  contactType: null,
  textWithAction: null,
};

export default StatusInfo;
