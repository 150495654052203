import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import MaintenanceFeeLTR from '../../../../components/maintenance-fee-ltr/maintenance-fee-ltr';

const FEATURE_NAME = 'maintenance_fee_vis';

const mapStateToProps = ({ components: { [FEATURE_NAME]: label } }) => label;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(MaintenanceFeeLTR);
