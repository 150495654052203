import React from 'react';
import { shape, string, number, arrayOf, bool } from 'prop-types';
import Card, { CardContent } from '@andes/card';
import classnames from 'classnames';
import HighlightedSpecsRes from '../highlighted-specs-res';
import Image from '../image';
import StyledLabel from '../styled-label';

const namespace = 'ui-vip-models-card';

const ModelsCard = ({ model, className, mounted }) => (
  <Card className={classnames(namespace, className)} shadow="outline" paddingSize={0}>
    <CardContent className={`${namespace}__card-container`}>
      <div className={`${namespace}__image-container`}>
        <Image
          className={`${namespace}__image-container__image`}
          id={model.picture.id}
          src={model.picture.thumbnail.src2x || model.picture.thumbnail.src}
          alt={model.picture.alt}
          onload="on"
          lazyload
          mounted={mounted}
        />
      </div>
      <div className={`${namespace}__specs-container`}>
        {model?.title && (
          <div className={`${namespace}__specs-price`}>
            <StyledLabel as="p" {...model.title} />
            <StyledLabel as="h2" {...model.subtitle} />
          </div>
        )}
        <HighlightedSpecsRes
          specs={model.attributes}
          className={model.attributes.length === 2 ? `${namespace}__specs-with-max-heigh` : null}
        />
      </div>
    </CardContent>
  </Card>
);

ModelsCard.propTypes = {
  model: shape({
    id: number,
    picture: shape({
      id: string,
      alt: string,
      width: number,
      height: number,
      src: string,
      src2x: string,
      thumbnail: shape({
        src: string,
        src2x: string,
      }),
    }),
    attributes: arrayOf(
      shape({
        icon: shape({
          id: string,
          color: string,
          size: string,
        }),
      }),
    ),
    action: shape({
      target: string,
      action_type: string,
      track: shape({}),
    }),
  }).isRequired,
  className: string,
  mounted: bool,
};

ModelsCard.defaultProps = {
  className: '',
};

export default ModelsCard;
