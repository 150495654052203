import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip/vis';

const FEATURE_NAME = 'points_of_interest';

const mapStateToProps = ({ [FEATURE_NAME]: poi }) => poi;

const mapDispatchToProps = dispatch => ({
  onScrollComponent: ({ focus }) => dispatch(actions.onScrollComponent({ component: 'points_of_interest', focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
