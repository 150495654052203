import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions/vip/vis';
import Quotation from '../../../../components/quotations/quotation.desktop';
import jsonToProps from '../../../../components/quotations/jsonToProps';
import modelsJsonToProps from '../../../../components/models-carousel/jsonToProps';

const FEATURE_NAME = 'quotation';

const mapStateToProps = (
  { [FEATURE_NAME]: quotation, picture_config, isGuest },
  { resizeModal, modelCarouselSelected, recaptchaSiteKey },
) => ({
  ...jsonToProps(quotation),
  models: modelsJsonToProps({ elements: quotation.models, picture_config }),
  modelUnits: quotation.model_units,
  modelCarouselSelected,
  currentState: quotation.currentState,
  recaptchaSiteKey,
  resizeModal,
  isGuest,
});

const mapDispatchToProps = dispatch => ({
  updateForm: form => dispatch(actions.updateQuotationForm(form)),
  getModelUnits: ({ modelId, deviceType, currentState }) => {
    dispatch(actions.getModelUnits(modelId, deviceType, currentState));
  },
  getModelUnitsUnregistered: ({ modelId, deviceType, currentState }) => {
    dispatch(actions.fetchUnregisteredQuotations(modelId, deviceType, currentState));
  },
  createQuotation: ({ unitId, recaptchaTokenV2, track, recaptchaSiteKey }) => {
    dispatch(actions.onCreateQuotation({ unitId, recaptchaTokenV2, track, recaptchaSiteKey }));
  },
  createQuotationShowPrice: ({
    unitId,
    recaptchaTokenV2,
    track,
    recaptchaSiteKey,
    modelId,
    deviceType,
    currentState,
  }) => {
    dispatch(
      actions.onShowPrice({ unitId, recaptchaTokenV2, track, recaptchaSiteKey, modelId, deviceType, currentState }),
    );
  },
  createUnregisteredQuotation: ({ unitId, form, recaptchaTokenV2, track, recaptchaSiteKey, currentState }) => {
    dispatch(
      actions.onCreateUnregisteredQuotation({ unitId, form, recaptchaTokenV2, track, recaptchaSiteKey, currentState }),
    );
  },
  updateCurrentState: currentState => {
    dispatch(actions.updateQuotationCurrentState(currentState));
  },
  redirectToLogin: (itemId, loginType, featureName) =>
    dispatch(actions.redirectToLogin({ itemId, loginType, featureName })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Quotation);
export { mapDispatchToProps };
