import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/highlighted-specs-res/jsonToProps';
import HighlightedSpecsRes from '../../../../components/highlighted-specs-res';

const FEATURE_NAME = 'highlighted_specs_res';

const mapStateToProps = ({ components: { [FEATURE_NAME]: highlighted_specs_res } }) =>
  jsonToProps(highlighted_specs_res);

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(HighlightedSpecsRes);
