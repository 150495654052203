import get from 'lodash/get';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip/vis';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/models-carousel/jsonToProps';
import { ACTION_TYPES } from '../../../../components/buybox/actions/utils/action-types';

const FEATURE_NAME = 'multifamily_carrousel';

const mapStateToProps = ({ components: { [FEATURE_NAME]: modelsCarousel }, picture_config, recaptchaSiteKey }) => ({
  title: modelsCarousel.title,
  models: jsonToProps({ picture_config, elements: modelsCarousel.models }),
  actionType: get(modelsCarousel, 'models[0].action.action_type', ACTION_TYPES.MODAL_CHECK_UNITS),
  recaptchaSiteKey,
});

const mapDispatchToProps = dispatch => ({
  getModelUnits: (modelId, deviceType, currentState) =>
    dispatch(actions.getUnitsLTR(modelId, deviceType, currentState)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
