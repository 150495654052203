import React from 'react';
import { string, shape } from 'prop-types';
import StyledLabel from '../styled-label';
import MediaAction from '../media/media-action';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-pdp-report-problem';

const ReportProblem = ({ linkLabel, url, target, highlightedText }) => (
  <div className={`${namespace}__property-link`}>
    <StyledLabel {...linkLabel.label} as="span">
      <MediaAction
        label={highlightedText}
        target={url}
        htmlTarget={target}
        onClick={() => {
          trackEvent(linkLabel.track);
        }}
      />
    </StyledLabel>
  </div>
);

ReportProblem.propTypes = {
  linkLabel: shape({
    label: shape({
      text: string.isRequired,
      color: string,
      font_size: string,
      font_family: string,
    }).isRequired,
  }),
  url: string.isRequired,
  target: string.isRequired,
  highlightedText: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
  }).isRequired,
};

ReportProblem.defaultProps = {
  linkLabel: null,
};

export default ReportProblem;
