import React from 'react';
import { string, shape, oneOfType, number } from 'prop-types';
import classnames from 'classnames';
import Image from '../../image';
import StyledLabel from '../../styled-label';
import componentEnhance from '../../../lib/component-enhance';
import IconFactory from '../../icons/factory-builder';
import { trackEvent } from '../../../lib/tracking';

const namespace = 'ui-vip-profile-info';

const ProfileInfo = ({ title, subtitle, logo, action, userStatusIcon, logo_style, label }) => {
  const logoContainerClasses = classnames(`${namespace}__logo-container`, `${namespace}__logo-container-${logo_style}`);

  const onClickHandler = () => {
    if (action && action.track) {
      trackEvent(action.track);
    }
  };

  const userStatusIconContent = userStatusIcon && IconFactory(userStatusIcon, `${namespace}__icon-normal`);
  const logoImage = logo && (
    <Image
      className={classnames(`${namespace}__logo-image`, `${namespace}__logo-${logo_style}`)}
      src={logo.url.src}
      alt={componentEnhance.jsx(title.text, title.values)}
      aria-hidden="true"
    />
  );
  const userTitle = (
    <>
      <StyledLabel
        as="h3"
        color={title.color}
        font_family={title.font_family}
        font_size={title.font_size}
        text={componentEnhance.jsx(title.text, title.values)}
      />
      {userStatusIconContent}
    </>
  );

  return (
    <div className={namespace}>
      {logo && action && action.target && (
        <a
          data-testid={`${namespace}__logo-container`}
          className={logoContainerClasses}
          href={action && action.target}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClickHandler}
        >
          {logoImage}
        </a>
      )}

      {logo && !action && (
        <div data-testid={`${namespace}__logo-container`} className={logoContainerClasses}>
          {logoImage}
        </div>
      )}

      <div className={`${namespace}__info-container`}>
        {action && action.target ? (
          <a
            className={`${namespace}__info-link`}
            href={action && action.target}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClickHandler}
          >
            {userTitle}
          </a>
        ) : (
          <div className={`${namespace}__info-link`}>{userTitle}</div>
        )}

        {subtitle && (
          <StyledLabel
            color={subtitle.color}
            className={`${namespace}__subtitle`}
            key={subtitle.text}
            text={componentEnhance.jsx(subtitle.text || subtitle, subtitle.values)}
          />
        )}

        {label && (
          <StyledLabel
            color={label.color}
            className={`${namespace}__label`}
            key={label.text}
            text={componentEnhance.jsx(label.text || label, label.values)}
          />
        )}
      </div>
    </div>
  );
};

ProfileInfo.propTypes = {
  title: oneOfType([
    shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
    }),
    shape({
      text: string,
      values: shape(),
      color: string,
      font_size: string,
      font_family: string,
    }),
  ]).isRequired,
  subtitle: oneOfType([
    shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
    }),
    shape({
      text: string,
      values: shape(),
      color: string,
      font_size: string,
      font_family: string,
    }),
  ]),
  userStatusIcon: shape({
    id: string,
  }),
  logo: shape({
    alt: string,
    url: shape({
      src: string,
    }).isRequired,
    width: number,
    height: number,
  }),
  logo_style: string,
  action: shape({
    target: string,
    track: shape({ melidata_event: shape({}), analytics_event: shape({}) }),
  }),
  label: oneOfType([
    shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
    }),
    shape({
      text: string,
      values: shape(),
      color: string,
      font_size: string,
      font_family: string,
    }),
  ]),
};

ProfileInfo.defaultProps = {
  subtitle: null,
  userStatusIcon: null,
  logo: null,
  action: null,
  logo_style: null,
};

export default ProfileInfo;
