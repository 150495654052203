import React from 'react';
import { shape, string } from 'prop-types';
import componentEnhance from '../../../lib/component-enhance';

const QuotationDisclaimer = ({ disclaimer: { text, values }, namespace }) => (
  <span className={`${namespace}__disclaimer`}>{componentEnhance.jsx(text, values)}</span>
);

QuotationDisclaimer.propTypes = {
  disclaimer: shape({
    text: string,
    values: shape({}),
  }),
  namespace: string,
};

export default QuotationDisclaimer;
