import React from 'react';
import classnames from 'classnames';
import { loadable } from 'nordic/lazy';
import { string, shape, bool, arrayOf } from 'prop-types';
import StyledLabel from '../styled-label';
import IconFactory from '../icons/factory-builder';

const CardTooltip = loadable(() => import('../card-tooltip'));

const namespace = 'ui-pdp-online-visit-request-label';
const ICON_ID = 'calendar';

const OnlineVisitRequestLabel = ({ onlineVisitRequestLabel }) => {
  const isTooltip = onlineVisitRequestLabel?.type === 'tooltip';
  const className = classnames(namespace, {
    [`${namespace}-tooltip`]: isTooltip,
    [`${namespace}-card`]: !isTooltip,
  });

  return (
    <div className={className}>
      {isTooltip ? (
        <CardTooltip className={`${className}-container`} {...onlineVisitRequestLabel} />
      ) : (
        <>
          {IconFactory(ICON_ID, 'ui-pdp-icon--online-visit-request-label-card')}
          <div className={`${className}__labels-container`}>
            {onlineVisitRequestLabel.labels.map(label => (
              <StyledLabel key={label.text} as="span" className={`${className}-text`} {...label} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

OnlineVisitRequestLabel.propTypes = {
  onlineVisitRequestLabel: shape({
    type: string,
    title: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
    icon: shape({
      id: string.isRequired,
      color: string.isRequired,
      size: string.isRequired,
    }).isRequired,
    id: string,
    closeable: bool,
    closed: bool,
    has_shadow: bool,
    arrow_type: string,
    storable: bool,
    behaviour: string.isRequired,
    className: string,
    labels: arrayOf(
      shape({
        text: string,
        color: string,
      }).isRequired,
    ).isRequired,
  }),
};

OnlineVisitRequestLabel.defaultProps = {
  id: null,
  type: null,
  closeable: false,
  closed: false,
  has_shadow: true,
  arrow_type: null,
  storeable: false,
};

export default OnlineVisitRequestLabel;
