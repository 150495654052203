import React from 'react';
import { shape, string, func, arrayOf } from 'prop-types';
import { Button } from '@andes/button';
import { trackEvent } from '../../../lib/tracking';

const TooltipAction = ({ tooltip, localStorageKey, setViewTooltip }) => {
  const handleAction = () => {
    localStorage.setItem(localStorageKey, true);
    trackEvent(tooltip.action?.primary?.track);
    setViewTooltip(false);
  };

  const handleCancelAction = () => {
    trackEvent(tooltip.action?.cancel?.track);
    setViewTooltip(false);
  };

  return (
    <>
      <Button hierarchy="loud" size="medium" onClick={handleAction}>
        {tooltip.actions[0].label}
      </Button>
      <Button hierarchy="transparent" size="medium" onClick={handleCancelAction}>
        {tooltip.actions[1].label}
      </Button>
    </>
  );
};

TooltipAction.propTypes = {
  tooltip: shape({
    content: string,
    trigger: string,
    type: string,
    side: string,
    actions: arrayOf(
      shape({
        label: string.isRequired,
      }).isRequired,
    ).isRequired,
  }),
  localStorageKey: string,
  setViewTooltip: func,
  action: shape({
    primary: shape({
      track: shape({}),
    }),
    cancel: shape({
      track: shape({}),
    }),
  }),
};

export default TooltipAction;
