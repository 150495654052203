import React from 'react';
import { bool, shape, string, func } from 'prop-types';
import UnregisteredPreLogin from '../commons/unregistered-pre-login';
import UnregisteredForm from '../commons/unregistered-form';
import StyledLabel from '../../../styled-label';

const namespace = 'ui-pdp-unregistered-quotation-no-tabs';

const UnregisteredWithoutTabsDesktop = ({
  form,
  selection_info,
  selectedUnit,
  selectedModel,
  updateForm,
  onSubmit,
  messages,
  recaptchaPublicKey,
  url,
  className,
  isPostingQuotation,
  stepBack,
  preLogin,
  preUnregistered,
  step,
  handleOnSubmit,
  onRemove,
  chooseStep,
  setRecaptchaTokenV2,
  replaceText,
  removeForm,
  privacyDisclaimer,
}) => {
  const views = {
    prelogin: (
      <UnregisteredPreLogin
        showUnregistered={() => chooseStep()}
        namespace="ui-pdp-unregistered-pre-login"
        {...preLogin}
      />
    ),
    unregisteredShield: (
      <UnregisteredPreLogin
        stepBack={stepBack}
        selection_info={selection_info}
        selectedModel={selectedModel}
        selectedUnit={selectedUnit}
        showUnregistered={() => removeForm()}
        replaceText={replaceText}
        onRemove={onRemove}
        isPostingQuotation={isPostingQuotation}
        handleOnSubmit={handleOnSubmit}
        isUnregistered
        namespace="ui-pdp-pre-unregistered"
        url={url}
        recaptchaPublicKey={recaptchaPublicKey}
        setRecaptchaTokenV2={setRecaptchaTokenV2}
        {...preUnregistered}
      />
    ),
    unregisteredAccess: (
      <div className={`${namespace}`}>
        <UnregisteredForm
          updateForm={updateForm}
          onSubmit={onSubmit}
          messages={messages}
          recaptchaPublicKey={recaptchaPublicKey}
          url={url}
          className={className}
          isPostingQuotation={isPostingQuotation}
          selection_info={selection_info}
          preLogin={preLogin}
          privacyDisclaimer={privacyDisclaimer}
          {...form}
        />
        <div className={`${namespace}__collapsible-info`}>
          <div className={`${namespace}__collapsible-info__container`}>
            <StyledLabel
              as="p"
              key="titleLabel"
              className={`${namespace}__collapsible-info-title`}
              {...selection_info.title}
            />
            <div>
              <StyledLabel as="span" key="unitLabel" {...selection_info.icon} />
              <span className={`${namespace}__collapsible-info-unit`}>Unidad {selectedUnit.name.text}</span>
            </div>
            <div>
              <StyledLabel as="span" key="modelLabel" {...selection_info.icon} />
              <span className={`${namespace}__collapsible-info-model`}>{selectedModel.picture.alt}</span>
            </div>
            <div className={`${namespace}__collapsible-info__edit`}>
              <StyledLabel
                {...selection_info.collapsible_info.edit_link}
                className={`${namespace}__collapsible-info__edit`}
                as="a"
                onClick={stepBack}
                data-testid="edit_link"
              />
            </div>
          </div>
        </div>
      </div>
    ),
  };

  return <>{views[step]}</>;
};

UnregisteredWithoutTabsDesktop.propTypes = {
  selection_info: shape({
    title: shape({
      text: string,
    }),
    collapsible_info: shape({
      icon: shape({
        id: string,
      }),
    }),
  }),
  selectedUnit: shape({
    name: shape({
      text: string,
    }),
  }),
  selectedModel: shape({}),
  updateForm: func.isRequired,
  onSubmit: func.isRequired,
  messages: shape({}),
  recaptchaPublicKey: string,
  url: string,
  className: string,
  isPostingQuotation: bool,
  redirectToLogin: func,
  stepBack: func,
  model_label: shape({}),
  unit_label: shape({}),
  preLogin: shape({}),
  preUnregistered: shape({}),
  step: string,
  handleOnSubmit: func,
  onRemove: func,
  chooseStep: func,
  setRecaptchaTokenV2: func,
  replaceText: shape({}),
  removeForm: func,
  form: shape({}),
  privacyDisclaimer: shape({}),
};

UnregisteredWithoutTabsDesktop.defaultProps = {
  selection_info: null,
  selectedUnit: null,
  selectedModel: null,
  messages: null,
  recaptchaPublicKey: null,
  url: null,
  className: null,
  isPostingQuotation: false,
  redirectToLogin: null,
  stepBack: null,
  model_label: null,
  unit_label: null,
  privacyDisclaimer: null,
};

export default UnregisteredWithoutTabsDesktop;
