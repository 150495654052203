import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import PriceComparison from '../../../../components/price-comparison/price-comparison';
import * as actions from '../../../../actions/vip/vis';

const FEATURE_NAME = 'price_comparison';

const mapStateToProps = ({ [FEATURE_NAME]: priceComparison }) => priceComparison;

const mapDispatchToProps = dispatch => ({
  onScrollComponent: ({ focus }) => dispatch(actions.onScrollComponent({ component: 'price_comparison', focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(PriceComparison);
