/* eslint-disable security/detect-unsafe-regex */
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const rutRegex = /^(\d{1,3}(?:(\.?)\d{3}){2}(-?)[\dkK])$/;
const defaultMaxLength = 120;

const validations = {};

validations.isRequired = (value, validation) => validation.value && value && String(value).trim().length > 0;

validations.isEmail = (value, validation) => validation.value && emailRegex.test(String(value).toLocaleLowerCase());

validations.isRut = (value, validation) => {
  if (!validation.value) {
    return false;
  }
  if (!rutRegex.test(String(value))) {
    return false;
  }

  const fullRut = String(value)
    .trim()
    .replace(/[.|-]/g, '');

  let rut = parseInt(fullRut.slice(0, -1), 10);
  const realDv = fullRut.slice(-1).toLocaleLowerCase();

  let m = 0;
  let s = 1;
  for (; rut; rut = Math.floor(rut / 10)) {
    s = (s + (rut % 10) * (9 - (m % 6))) % 11;
    m += 1;
  }
  const dv = s ? s - 1 : 'k';

  return realDv === dv.toString();
};

validations.minLength = (value, validation) => value.trim().length >= validation.value;

validations.maxLength = (value, validation) => value.trim().length <= validation.value;

const validate = (value, fieldValidations) => {
  const inputValidations = fieldValidations.reduce((obj, item) => {
    obj[item.type] = item;
    return obj;
  }, {});

  // valitations ordered by priority
  const validationsOrder = ['isRequired', 'isEmail', 'isRut', 'minLength', 'maxLength'];
  let error = null;

  validationsOrder.some(validationName => {
    if (
      inputValidations[`${validationName}`] &&
      !validations[`${validationName}`](value || '', inputValidations[`${validationName}`])
    ) {
      error = inputValidations[`${validationName}`].error_message;
      return true;
    }
    return false;
  });

  return error;
};

const getValidationByType = (fieldValidations, type) =>
  fieldValidations ? fieldValidations.find(item => item.type === type) : null;

const getMaxLength = fieldValidations => {
  const validation = getValidationByType(fieldValidations, 'maxLength');
  return validation ? validation.value : defaultMaxLength;
};

const formatRut = value => {
  if (!rutRegex.test(String(value))) {
    return null;
  }

  const rut = String(value)
    .trim()
    .replace(/[.|-]/g, '');
  return `${rut.slice(0, -1)}-${rut.slice(-1).toLocaleLowerCase()}`;
};

export { validate, getMaxLength, formatRut };
