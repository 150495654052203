import React from 'react';
import { bool, shape, string, func } from 'prop-types';
import { UnregisteredWithoutTabs } from './components/commons';

const UnregisteredDesktop = ({
  selection_info,
  title,
  selectedUnit,
  selectedModel,
  updateForm,
  onSubmit,
  messages,
  recaptchaPublicKey,
  url,
  className,
  isPostingQuotation,
  redirectToLogin,
  stepBack,
  model_label,
  unit_label,
  pre_login,
  pre_unregistered,
  form,
  ...rest
}) => (
  <UnregisteredWithoutTabs
    selection_info={selection_info}
    selectedUnit={selectedUnit}
    selectedModel={selectedModel}
    updateForm={updateForm}
    onSubmit={onSubmit}
    messages={messages}
    recaptchaPublicKey={recaptchaPublicKey}
    url={url}
    className={className}
    isPostingQuotation={isPostingQuotation}
    redirectToLogin={redirectToLogin}
    stepBack={stepBack}
    preLogin={pre_login}
    preUnregistered={pre_unregistered}
    form={form}
    {...rest}
  />
);

UnregisteredDesktop.propTypes = {
  selection_info: shape({
    title: shape({
      text: string,
    }),
    collapsible_info: shape({
      icon: shape({
        id: string,
      }),
    }),
  }),
  title: shape({ text: string }).isRequired,
  selectedUnit: shape({
    name: shape({
      text: string,
    }),
  }),
  selectedModel: shape({}),
  updateForm: func.isRequired,
  onSubmit: func.isRequired,
  messages: shape({}),
  recaptchaPublicKey: string,
  url: string,
  className: string,
  isPostingQuotation: bool,
  redirectToLogin: func,
  stepBack: func,
  model_label: shape({}),
  unit_label: shape({}),
  form: shape({}).isRequired,
  pre_login: shape({
    title: shape({
      text: string,
    }),
    subtitle: shape({
      text: string,
    }),
    action: shape({
      label: shape({
        text: string,
      }),
      track: shape({}),
    }),
    confirm: shape({
      label: shape({
        text: string,
      }),
    }),
  }),
  pre_unregistered: shape({
    title: shape({
      text: string,
    }),
    subtitle: shape({
      text: string,
    }),
    action: shape({
      label: shape({
        text: string,
      }),
    }),
    confirm: shape({
      label: shape({
        text: string,
      }),
    }),
  }),
};

UnregisteredDesktop.defaultProps = {
  selection_info: null,
  selectedUnit: null,
  selectedModel: null,
  messages: null,
  recaptchaPublicKey: null,
  url: null,
  className: null,
  isPostingQuotation: false,
  redirectToLogin: null,
  stepBack: null,
  model_label: null,
  unit_label: null,
};

export default UnregisteredDesktop;
