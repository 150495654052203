import React, { useRef, useState, useEffect, Fragment } from 'react';
import { Button } from '@andes/button';
import { Typography } from '@andes/typography';
import { string, arrayOf, shape } from 'prop-types';
import StyledLabel from '../styled-label';
import Grid from '../grid';
import { generateKeyToComponent } from '../../utils/validators';
import IconFactory from '../icons/factory-builder';
import { Tooltip } from '@andes/tooltip';
import { trackEvent } from '../../lib/tracking';
import classnames from 'classnames';

const { Container, Row, Col } = Grid;
const namespace = 'requirements-card-ltr';

const REQUIREMENTS_NEW_EXPERIENCE = 'long_term_rental_requirements_new';

const tooltipTitleBuilder = tooltip =>
  tooltip ? <StyledLabel as="h2" data-testid="tooltip-title" {...tooltip.title} /> : '';

const tooltipContentBuilder = tooltip =>
  tooltip ? (
    <>
      {tooltip.lists?.map(list => (
        <Row key={generateKeyToComponent(list)} className={`${namespace}__tooltip-content`}>
          <StyledLabel
            font_family={list.font_family}
            font_size={list.font_size}
            className={`${namespace}__tooltip-content-title`}
            text={list.text}
            color={list.color}
            data-testid="tooltip-content-title"
          />
          {list.content.map(item => (
            <StyledLabel key={generateKeyToComponent(item)} className={`${namespace}__tooltip-content-item`} as="p">
              <StyledLabel
                className={`${namespace}__char-icon`}
                as="span"
                text={item.char_icon.text}
                font_family={item.char_icon.font_family}
                font_size={item.char_icon.font_size}
              />
              <StyledLabel
                as="span"
                text={item.label.text}
                font_family={item.label.font_family}
                font_size={item.label.font_size}
                color={item.label.color}
              />
            </StyledLabel>
          ))}
        </Row>
      ))}
      {tooltip.bottom && (
        <Row className={`${namespace}__tooltip-footer`}>
          <StyledLabel
            className={`${namespace}__tooltip-footer-icon`}
            text={IconFactory(tooltip.bottom.icon)}
            as="span"
          />
          <StyledLabel
            className={`${namespace}__tooltip-footer-text`}
            as="span"
            text={tooltip.bottom.text}
            data-testid="tooltip-footer-text"
            font_size="XSMALL"
          />
        </Row>
      )}
    </>
  ) : (
    ''
  );

const detailLink = element => (
  <Typography
    component="a"
    color="link"
    href={element.action.target}
    className={`${namespace}__action`}
    onClick={() => {
      trackEvent(element.action.track);
    }}
  >
    {element.action.label.text}
  </Typography>
);

const columnBuilder = (column, side) => (
  <>
    {column.map(element => (
      <Fragment key={generateKeyToComponent({ ...element, ...side })}>
        <Container className={`${namespace}__req-item`}>
          <StyledLabel className={`${namespace}__req-item-icon`} text={IconFactory(element.icon)} as="span" />
          <Row>
            <StyledLabel
              className={`${namespace}__req-item-title`}
              as="span"
              text={element.title}
              data-testid="req-item-text"
              font_family={element.font_family}
              font_size={element.font_size}
            >
              {element?.action &&
                (element?.collapse ? (
                  <Tooltip
                    title={tooltipTitleBuilder(element.collapse.tooltip)}
                    content={tooltipContentBuilder(element.collapse.tooltip)}
                    trigger="click"
                    side="bottomLeft"
                  >
                    {detailLink(element)}
                  </Tooltip>
                ) : (
                  detailLink(element)
                ))}
            </StyledLabel>
            {element.subtitle && (
              <StyledLabel
                className={`${namespace}__req-item-subtitle`}
                as="span"
                text={element.subtitle}
                font_family={element.font_family}
                font_size={element.font_size}
              />
            )}
          </Row>
        </Container>
        {element.lists?.map(list => (
          <Row key={generateKeyToComponent(list)} className={`${namespace}__list`}>
            <StyledLabel
              font_family={list.font_family}
              font_size={element.font_size}
              className={`${namespace}__list-title`}
              text={list.text}
              data-testid="list-title"
            />
            {list.content.map(item => (
              <StyledLabel key={generateKeyToComponent(item)} className={`${namespace}__list-item`} as="p">
                <StyledLabel
                  className={`${namespace}__char-icon`}
                  as="span"
                  text={item.char_icon.text}
                  font_family={item.char_icon.font_family}
                  font_size={item.char_icon.font_size}
                />
                <StyledLabel
                  as="span"
                  text={item.label.text}
                  font_family={item.label.font_family}
                  font_size={item.label.font_size}
                />
              </StyledLabel>
            ))}
          </Row>
        ))}
      </Fragment>
    ))}
  </>
);

const RequirementsCardLTR = ({ title, subtitle, content_left, content_right, action, component_id, id }) => {
  const targetComponentRef = useRef(null);
  const [showButton, setShowButton] = useState(false);

  const componentId = component_id || id;

  useEffect(() => {
    const handleScroll = () => {
      const targetComponentTop = targetComponentRef?.current?.offsetTop;
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      const buttonIsVisible = scrollPosition >= targetComponentTop - windowHeight / 2;
      setShowButton(buttonIsVisible);
    };

    if (action) {
      window.addEventListener('scroll', handleScroll);
      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }

    return () => {};
  }, [action]);

  return (
    <div ref={targetComponentRef} id="long-term-rental-requirements">
      <Row
        className={classnames(namespace, {
          [`${namespace}-rex`]: componentId === REQUIREMENTS_NEW_EXPERIENCE,
        })}
      >
        <StyledLabel className={`${namespace}__title`} as="h2" text={title} />
        <StyledLabel className={`${namespace}__subtitle`} as="span" text={subtitle} font_size="XSMALL" />
        <Container className={`${namespace}__content`}>
          {content_left && <Col className={`${namespace}__content-left`}>{columnBuilder(content_left, 'left')}</Col>}
          {content_right && (
            <Col className={`${namespace}__content-right`}>{columnBuilder(content_right, 'right')}</Col>
          )}
        </Container>
      </Row>
      {action && showButton && (
        <div className={`${namespace}__drawer`}>
          <Button
            className={`${namespace}__drawer__button`}
            hierarchy={action.hierarchy}
            type={action.type}
            onClick={() => {
              trackEvent(action.track);
            }}
            href={action.target}
            data-testid="sticky-button"
          >
            {action.label.text}
          </Button>
        </div>
      )}
    </div>
  );
};

RequirementsCardLTR.propTypes = {
  component_id: string.isRequired,
  id: string.isRequired,
  title: string.isRequired,
  subtitle: string,
  content_left: arrayOf(
    shape({
      icon: shape({ id: string.isRequired, color: string.isRequired, size: string.isRequired }),
      title: string.isRequired,
      lists: arrayOf(
        shape({
          char_icon: shape({
            text: string.isRequired,
            color: string.isRequired,
            font_size: string.isRequired,
            font_family: string.isRequired,
          }),
          label: shape({
            text: string.isRequired,
            color: string.isRequired,
            font_size: string.isRequired,
            font_family: string.isRequired,
          }),
        }),
      ),
    }),
  ).isRequired,
  content_right: arrayOf(
    shape({
      icon: shape({ id: string.isRequired, color: string.isRequired, size: string.isRequired }),
      title: string.isRequired,
      lists: arrayOf(
        shape({
          char_icon: shape({
            text: string.isRequired,
            color: string.isRequired,
            font_size: string.isRequired,
            font_family: string.isRequired,
          }),
          label: shape({
            text: string.isRequired,
            color: string.isRequired,
            font_size: string.isRequired,
            font_family: string.isRequired,
          }),
        }),
      ),
    }),
  ).isRequired,
  action: shape({
    label: shape({
      text: string.isRequired,
    }).isRequired,
    hierarchy: string,
    type: string,
    track: shape({}),
  }),
};

RequirementsCardLTR.defaultProps = {
  title: 'Requisitos para arrendar esta propiedad',
  content_left: null,
  content_right: null,
};

export default RequirementsCardLTR;
