import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Label, ComposedLabel } from '../../prop-types';
import GroupedMainActions from '../buybox/actions/container-actions';
import BoxList from '../box-list/box-list';
import StyledLabel from '../styled-label';
import Media from '../media';

const namespace = 'ui-pdp-online-rental-details';

const OnlineRentalDetails = ({ contentRows, extraInfo, footer, ...containerActionProps }) => (
  <div className={namespace}>
    <BoxList content_rows={contentRows} />
    {extraInfo.map(label => (
      <StyledLabel key={`extra-info-${label.text}`} {...label} className={`${namespace}__extra-info-item`} />
    ))}
    <GroupedMainActions {...containerActionProps} />
    <div className={`${namespace}__footer`}>
      {footer.map(({ label, icon }) => (
        <Media key={`footer-${label.text}`} title={{ ...label, plain: true }} icon={icon} />
      ))}
    </div>
  </div>
);

OnlineRentalDetails.propTypes = {
  containerActions: arrayOf(
    shape({
      actions: arrayOf(
        shape({
          label: Label.isRequired,
          type: string.isRequired,
          target: string.isRequired,
          track: shape({}),
        }),
      ),
    }),
  ).isRequired,
  contentRows: arrayOf(
    shape({
      char_icon: Label.isRequired,
      title: Label.isRequired,
    }),
  ).isRequired,
  extraInfo: arrayOf(Label).isRequired,
  footer: arrayOf(
    shape({
      label: ComposedLabel,
      icon: shape({ id: string.isRequired, color: string }),
    }),
  ).isRequired,
};

export default OnlineRentalDetails;
