import React, { useEffect } from 'react';
import { shape, string, func, bool, oneOf, arrayOf, number } from 'prop-types';
import classnames from 'classnames';
import QuotationModalContent from './quotations-modal-content';
import UnregisteredDesktop from '../unregistered-modal/unregistered.desktop';
import { Recaptchav3 } from '../recaptcha';
import { IS_POSTING } from '../unregistered-modal/utils/constants';

const namespace = 'ui-pdp-quotation';

const Quotation = ({
  className,
  form,
  createQuotation,
  createQuotationShowPrice,
  createUnregisteredQuotation,
  updateForm,
  url,
  messages,
  recaptchaPublicKey,
  recaptchaSiteKey,
  resizeModal,
  modelCarouselSelected,
  currentState,
  updateCurrentState,
  isPostingQuotation,
  track,
  redirectToLogin,
  tooltip,
  guestsTooltip,
  ...rest
}) => {
  const step = currentState.step || 0;
  const selectedUnit = currentState.selectedUnit || null;
  const { id: defaultSelectedModelId = null } = rest.models[0] || {};
  const selectedModelId = currentState.selectedModelId || defaultSelectedModelId;

  const handleUpdateModelOnCurrentState = modelId => {
    updateCurrentState({
      ...currentState,
      selectedModelId: modelId,
      isFetching: true,
    });
  };

  const handleUpdateUnitOnCurrentState = unit => {
    updateCurrentState({
      ...currentState,
      selectedUnit: unit,
    });
  };

  const handleUpdateStepOnCurrentState = newStep => {
    updateCurrentState({
      ...currentState,
      step: newStep,
    });
  };

  const handleUpdateContentStepOnCurrentState = newContentStep => {
    updateCurrentState({
      ...currentState,
      contentStep: newContentStep,
    });
  };

  const handleSubmitLogged = ({ recaptchaTokenV2 }) => {
    updateCurrentState({ step: form ? 1 : 0, selectedUnit, selectedModelId });

    if (form) {
      handleUpdateStepOnCurrentState(1);
    } else {
      createQuotation({
        unitId: selectedUnit.id,
        recaptchaTokenV2,
        track,
        recaptchaSiteKey,
      });
    }
  };

  const handleSubmitShowPrice = ({ recaptchaTokenV2, deviceType }) => {
    updateCurrentState({ step: form ? 1 : 0, selectedUnit, selectedModelId });
    createQuotationShowPrice({
      unitId: selectedUnit.id,
      recaptchaTokenV2,
      track,
      recaptchaSiteKey,
      modelId: selectedModelId,
      deviceType,
    });
  };

  const handleSubmitGuest = ({ filledForm, recaptchaTokenV2 }) => {
    createUnregisteredQuotation({
      unitId: selectedUnit.id,
      form: filledForm,
      recaptchaTokenV2,
      track,
      recaptchaSiteKey,
      currentState,
    });
  };

  useEffect(() => {
    if (resizeModal) {
      resizeModal();
    }
  });

  useEffect(() => {
    if (step === 0) {
      localStorage.removeItem(IS_POSTING);
    }
  }, [step]);

  return (
    <div className={classnames(namespace, className)}>
      <Recaptchav3 siteKey={recaptchaSiteKey} />
      {step === 0 && (
        <QuotationModalContent
          onSubmit={handleSubmitLogged}
          onChangeUnit={handleUpdateUnitOnCurrentState}
          onChangeModel={handleUpdateModelOnCurrentState}
          onShowPrice={handleSubmitShowPrice}
          selectedUnit={selectedUnit}
          selectedModelId={selectedModelId}
          recaptchaPublicKey={!form ? recaptchaPublicKey : null}
          isPostingQuotation={isPostingQuotation}
          isFetching={currentState.isFetching || selectedUnit == null}
          tooltip={tooltip}
          guestsTooltip={guestsTooltip}
          {...rest}
        />
      )}
      {step === 1 && (
        <UnregisteredDesktop
          className={`${namespace}-form`}
          onSubmit={handleSubmitGuest}
          url={url}
          messages={messages}
          recaptchaPublicKey={recaptchaPublicKey}
          stepBack={() => handleUpdateStepOnCurrentState(0)}
          {...form}
          redirectToLogin={redirectToLogin}
          updateForm={updateForm}
          selectedUnit={selectedUnit}
          selectedModel={rest.models.find(model => model.id === selectedModelId)}
          isPostingQuotation={isPostingQuotation}
          preStep={currentState.contentStep}
          setPreStep={handleUpdateContentStepOnCurrentState}
          form={form}
        />
      )}
    </div>
  );
};

Quotation.propTypes = {
  className: string,
  form: shape({}).isRequired,
  createQuotation: func.isRequired,
  createUnregisteredQuotation: func.isRequired,
  url: string,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
    form: shape({
      title: shape({ text: string.isRequired }),
      subtitles: arrayOf(
        shape({
          text: string.isRequired,
          icon: shape({
            id: string,
            color: string,
          }),
        }),
      ),
      conditions: shape({
        text: string.isRequired,
        values: shape(),
      }),
      confirm: shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
        item_id: string.isRequired,
      }).isRequired,
      name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      last_name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      email: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      phone: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      identification_number: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
    }),
    collapsible_info: shape({
      edit_link: shape({
        text: string.isRequired,
      }),
    }),
  }),
  tooltip: shape({
    content: string,
    trigger: string,
    type: string,
    side: string,
    action: {
      label: string,
    },
  }),
  track: shape({}),
  recaptchaPublicKey: string,
  recaptchaSiteKey: string,
  resizeModal: func.isRequired,
  updateForm: func.isRequired,
  modelCarouselSelected: number,
  models: QuotationModalContent.propTypes.models.isRequired,
  currentState: shape({}),
  updateCurrentState: func.isRequired,
  isPostingQuotation: bool,
  redirectToLogin: func,
  createQuotationShowPrice: func,
  guestsTooltip: {
    tooltip_price: shape({
      closeable: bool,
      side: string,
      content: string,
      trigger: string,
      type: string,
    }),
    actionable_icon_price: shape({
      id: string,
    }),
  },
};

Quotation.defaultProps = {
  className: null,
  url: null,
  messages: null,
  track: null,
  recaptchaPublicKey: null,
  recaptchaSiteKey: null,
  modelCarouselSelected: null,
  currentState: {},
  isPostingQuotation: false,
  redirectToLogin: null,
  createQuotationShowPrice: null,
};

export default React.memo(Quotation);
