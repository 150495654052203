/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { arrayOf, shape, func, number, string, bool } from 'prop-types';
import { ProgressButton, ButtonText } from '@andes/button';
import { Dropdown, DropdownItem } from '@andes/dropdown';
import { Tooltip } from '@andes/tooltip';
import { Tabs, Tab, TabContent } from '@andes/tabs';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';

import IconFactory from '../icons/factory-builder';
import colornames from '../../lib/colornames';
import StyledLabel from '../styled-label';
import Image from '../image';
import HighlightedSpecsRes from '../highlighted-specs-res';
import { Recaptchav2 } from '../recaptcha';
import { trackPage, trackEvent, trackEventWithCustomFields } from '../../lib/tracking';
import { TooltipAction, Disclaimer } from './commons';

const namespace = 'ui-vip-quotations';
const localStorageKey = 'quotation-tooltip';
const localStorageKeyQuoted = 'quoted-units';

const QuotationsModalContent = ({
  models,
  modelUnits,
  header,
  subtitle,
  fullAddress,
  getModelUnits,
  unitTitle,
  unitListTitle,
  onChangeUnit,
  onSubmit,
  onChangeModel,
  selectedUnit,
  selectedModelId,
  track,
  recaptchaPublicKey,
  isPostingQuotation,
  isFetching,
  tooltip,
  congratsQuote,
  guestsTooltip,
  subtitle_price,
  property_code,
  onShowPrice,
  isGuest,
  getModelUnitsUnregistered,
  exceedQuotationLimit,
}) => {
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState(null);
  const [viewTooltip, setViewTooltip] = useState(true);
  const tooltipChecked = localStorage.getItem(localStorageKey);
  const quotedItems = localStorage.getItem(localStorageKeyQuoted);
  const quotedUnits = quotedItems ? JSON.parse(quotedItems) : [];
  const selectedTab = models.findIndex(model => model.id === selectedModelId);

  const [showPriceTooltip, setShowPriceTooltip] = useState(true);

  const renderGuestsTooltip = () => {
    const { tooltip_price: tooltipPrice, actionable_icon_price: tooltipIcon } = guestsTooltip;
    const togglePriceTooltip = show => {
      const tooltipTrack = show ? tooltipIcon.track : tooltipPrice.actions[0].track;
      trackEvent(tooltipTrack);
      setShowPriceTooltip(show);
    };

    return (
      <Tooltip
        content={tooltipPrice.content}
        trigger={tooltipPrice.trigger}
        side={tooltipPrice.side}
        type={tooltipPrice.type}
        closeable={tooltipPrice.closeable}
        onClose={() => togglePriceTooltip(false)}
        onOpen={() => togglePriceTooltip(true)}
        open={showPriceTooltip}
      >
        <div>
          <StyledLabel as="p" {...selectedUnit.price} />
          <StyledLabel as="p" {...selectedUnit.conversion} />
        </div>
        <div role="tooltip" className="tooltip-container">
          <span>{IconFactory('HIDDEN-EYE', colornames('GRAY'))}</span>
        </div>
      </Tooltip>
    );
  };

  const currentModelUnits = modelUnits[selectedModelId];
  const handleTabClick = modelId => {
    const params = {
      modelId,
      deviceType: 'desktop',
    };
    if (selectedModelId !== modelId) {
      onChangeModel(modelId);
      if (isGuest) {
        getModelUnitsUnregistered(params);
        return;
      }
      getModelUnits(params);
    }
  };

  const handleShowPrice = e => {
    e.preventDefault();
    trackEvent(subtitle_price.track);
    onShowPrice({ recaptchaTokenV2, deviceType: 'desktop' });
  };

  const handleChangeUnit = (e, unitId) => {
    e.preventDefault();
    const unit = currentModelUnits.find(modelUnit => unitId === modelUnit.id);

    onChangeUnit(unit);
  };

  const handleCreateQuotation = e => {
    e.preventDefault();

    if (selectedUnit && selectedUnit.action.track) {
      const isQuoted = quotedUnits.includes(selectedUnit.id);
      trackEventWithCustomFields(selectedUnit.action.track, { as_buyer_quoted_item: isQuoted });
    }

    onSubmit({ recaptchaTokenV2 });
  };

  useEffect(() => {
    trackPage(track);
  }, [track]);

  const tabTitle = modelIndex => {
    const model = models[parseInt(modelIndex, 10)];
    return (
      <div className={`${namespace}__tab-title`}>
        <div className={`${namespace}__tab-title-picture-container`}>
          <Image
            className={`${namespace}__tab-title-picture`}
            key={model.id}
            id={model.picture.id}
            src={model.picture.thumbnail.src}
            alt={model.picture.alt}
            onload="on"
          />
        </div>
        <div className={`${namespace}__tab-title-attrs`}>
          {model.name && (
            <div className={`${namespace}__tab-name-container`}>
              <StyledLabel className={`${namespace}__tab-name`} key={model.name.text} as="span" {...model.name} />
            </div>
          )}
          {model.attributes.map(attribute => (
            <StyledLabel key={attribute.label.text} as="span" {...attribute.label} />
          ))}
        </div>
      </div>
    );
  };

  const hasModels = modelUnits && currentModelUnits;

  return (
    <div className={namespace}>
      <div className={`${namespace}__header`}>
        <div className={`${namespace}__title`}>
          <StyledLabel as="h1" {...header} />
          {subtitle_price ? (
            <>
              <StyledLabel as="h1" text="|" className={`${namespace}__show-price`} />
              {!tooltipChecked ? (
                <Tooltip
                  {...tooltip}
                  disableClickOutside
                  onClose={() => setViewTooltip(false)}
                  onOpen={() => setViewTooltip(true)}
                  actions={
                    <TooltipAction
                      tooltip={tooltip}
                      localStorageKey={localStorageKey}
                      setViewTooltip={setViewTooltip}
                    />
                  }
                  open={viewTooltip}
                  side="left"
                  triger="click"
                  closable={false}
                  className={`${namespace}__show-price-tooltip`}
                >
                  <span>{IconFactory('eye', colornames('BLUE'))}</span>
                </Tooltip>
              ) : (
                <span>{IconFactory('eye', colornames('BLUE'))}</span>
              )}
              <StyledLabel
                as="span"
                className={`${namespace}__show-price-icon`}
                {...subtitle_price.title}
                onClick={handleShowPrice}
              />
            </>
          ) : (
            <>
              <StyledLabel as="h2" {...subtitle} />
            </>
          )}
        </div>
        <div className={`${namespace}__subtitle`}>
          <StyledLabel as="span" {...fullAddress} />
          {property_code && (
            <>
              <StyledLabel as="span" text="|" className={`${namespace}__show-price`} />
              <StyledLabel as="span" {...property_code} />
            </>
          )}
        </div>
      </div>
      <div className={`${namespace}__models`}>
        {models.length > 0 ? (
          <div className={`${namespace}__tabs`}>
            <Tabs selectedTab={selectedTab}>
              {models.map((model, index) => (
                <Tab
                  key={model.id}
                  title={tabTitle(index)}
                  tabIndex={model.id}
                  onClick={() => handleTabClick(model.id)}
                  selected={selectedModelId === model.id}
                >
                  <TabContent id={model.id}>
                    {isFetching ? (
                      <div className={`${namespace}__spinner-container`}>
                        <ProgressIndicatorCircular modifier="block" size="large" />
                      </div>
                    ) : (
                      <>
                        <div className={`${namespace}__tab-left-column`}>
                          {selectedUnit && (
                            <div className={`${namespace}__tab-picture-container`}>
                              <Image
                                className={`${namespace}__tab-picture`}
                                key={selectedUnit.id}
                                id={selectedUnit.picture.id}
                                src={selectedUnit.picture.src}
                                alt={model.picture.alt}
                                onload="on"
                              />
                            </div>
                          )}
                        </div>
                        <div className={`${namespace}__tab-right-column`}>
                          <div>
                            {hasModels && currentModelUnits.length === 1 && <StyledLabel as="span" {...unitTitle} />}
                            <div className={`${namespace}__selector-container`}>
                              {hasModels && currentModelUnits.length > 1 && (
                                <Dropdown
                                  className={`${namespace}__unit-selector`}
                                  menuAlignment="bottom"
                                  onChange={handleChangeUnit}
                                  value={selectedUnit ? selectedUnit.id : null}
                                  label={unitListTitle.text}
                                  key={`dropdown--${selectedModelId}`}
                                  type="form"
                                  menuMaxHeight={320}
                                >
                                  {currentModelUnits.map(unit => (
                                    <DropdownItem
                                      key={unit.id}
                                      title={unit.name.text}
                                      description={unit.description.text}
                                      rightContent={<StyledLabel as="p" {...unit.price} />}
                                      value={unit.id}
                                    >
                                      {unit.conversion && (
                                        <div className={`${namespace}__unit-selector-conversion`}>
                                          <StyledLabel as="span" {...unit.conversion} />
                                        </div>
                                      )}
                                    </DropdownItem>
                                  ))}
                                </Dropdown>
                              )}
                              {modelUnits && selectedUnit && currentModelUnits && currentModelUnits.length === 1 && (
                                <div>
                                  <StyledLabel as="span" {...selectedUnit.name} />
                                </div>
                              )}
                            </div>
                            {selectedUnit?.price && (
                              <div className={`${namespace}__pricing`}>
                                {guestsTooltip ? (
                                  renderGuestsTooltip()
                                ) : (
                                  <div>
                                    {selectedUnit?.conversion ? (
                                      <>
                                        <StyledLabel as="p" {...selectedUnit.price} color="BLACK" />
                                        <StyledLabel as="p" {...selectedUnit.conversion} />
                                      </>
                                    ) : (
                                      <div style={{ display: 'flex' }}>
                                        <StyledLabel as="p" {...selectedUnit.price} color="GRAY" />
                                        <span className={`${namespace}__icon`}>
                                          {IconFactory('hidden-eye', colornames('GRAY'))}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {selectedUnit && (
                              <HighlightedSpecsRes
                                specs={selectedUnit.attributes}
                                className={`${namespace}__highlighted-specs`}
                              />
                            )}
                          </div>
                          {selectedUnit && (
                            <div>
                              {selectedUnit.privacy_disclaimer_label && (
                                <Disclaimer disclaimer={selectedUnit.privacy_disclaimer_label} namespace={namespace} />
                              )}
                              {recaptchaPublicKey && (
                                <Recaptchav2
                                  align="left"
                                  className={`${namespace}-recaptcha`}
                                  recaptchaPublicKey={recaptchaPublicKey}
                                  required
                                  lowEnd
                                  id="unregistered-quotation-recaptcha"
                                  verifyCallback={token => setRecaptchaTokenV2(token)}
                                />
                              )}
                              {quotedUnits.includes(selectedUnit.id) && congratsQuote && (
                                <div className={`${namespace}__congrats-quote`}>
                                  <div className="check-container">{IconFactory(congratsQuote.icon.id)}</div>
                                  <StyledLabel
                                    className={`${namespace}__congrats-quote-label`}
                                    {...congratsQuote.title}
                                  />
                                </div>
                              )}
                              <div className={`${namespace}__button-container`}>
                                <ProgressButton
                                  data-testid="submit-step-1"
                                  className={`${namespace}__button`}
                                  {...selectedUnit.action}
                                  onClick={handleCreateQuotation}
                                  disabled={(recaptchaPublicKey && !recaptchaTokenV2) || exceedQuotationLimit}
                                  progressLabel={selectedUnit.action?.label.text}
                                  loading={isPostingQuotation}
                                  fullWidth
                                >
                                  {selectedUnit.action && <ButtonText>{selectedUnit.action.label.text}</ButtonText>}
                                </ProgressButton>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </TabContent>
                </Tab>
              ))}
            </Tabs>
          </div>
        ) : null}
      </div>
    </div>
  );
};

QuotationsModalContent.propTypes = {
  models: arrayOf(
    shape({
      id: number.isRequired,
      picture: shape({
        alt: string,
        thumbnail: shape({}).isRequired,
      }).isRequired,
      attributes: arrayOf(
        shape({
          icon: shape({
            id: string,
            color: string,
            size: string,
          }).isRequired,
          label: shape({}).isRequired,
        }),
      ),
    }),
  ).isRequired,
  modelUnits: shape({}).isRequired,
  header: shape({
    text: string.isRequired,
  }).isRequired,
  subtitle: shape({
    text: string.isRequired,
  }).isRequired,
  fullAddress: shape({
    text: string.isRequired,
  }).isRequired,
  tooltip: shape({
    content: string,
    type: string,
    side: string,
    highlight: string,
    action: {
      label: string,
    },
  }),
  featureName: string,
  redirectToLogin: func,
  getModelUnits: func.isRequired,
  modelCarouselSelected: number,
  unitTitle: shape({}),
  unitListTitle: shape({}),
  onSubmit: func.isRequired,
  onChangeUnit: func.isRequired,
  onChangeModel: func.isRequired,
  selectedUnit: shape({}),
  selectedModelId: number,
  track: shape({}),
  recaptchaPublicKey: string,
  isPostingQuotation: bool,
  isFetching: bool,
  subtitle_price: shape({}),
  property_code: shape({}),
  onShowPrice: shape({}),
  congratsQuote: shape({
    icon: shape({
      id: string,
    }),
    title: shape({
      color: string,
      font_family: string,
      font_size: string,
      text: string,
    }),
  }),
  message_modal: {
    text: string,
    type: string,
  },
  guestsTooltip: {
    tooltip_price: shape({
      closeable: bool,
      side: string,
      content: string,
      trigger: string,
      type: string,
    }),
    actionable_icon_price: shape({
      id: string,
    }),
  },
  isGuest: bool,
  getModelUnitsUnregistered: func,
  exceedQuotationLimit: bool,
};

QuotationsModalContent.defaultProps = {
  modelCarouselSelected: null,
  unitTitle: {},
  unitListTitle: {},
  selectedModelId: null,
  selectedUnit: null,
  track: {},
  recaptchaPublicKey: null,
  isPostingQuotation: false,
  isFetching: false,
  exceedQuotationLimit: null,
};

export default QuotationsModalContent;
