import React from 'react';
import { shape, string, number, arrayOf } from 'prop-types';
import StyledLabel from '../styled-label';
import Image from '../image';
import { generateKeyToComponent } from '../../utils/validators';

const CreditOffer = ({ image, title, subtitles }) => (
  <div className="credit-offer__container">
    {image && (
      <figure className="credit-offer__brand">
        <Image
          data-testid={image.id}
          className="credit-offer__image"
          key={image.id}
          id={image.id}
          src={image.url.src}
          alt={image.alt}
          width={image.width}
          height={image.height}
        />
      </figure>
    )}
    {title && (
      <div className="credit-offer__wording">
        <StyledLabel as="p" className="credit-offer__title" {...title} />
        {subtitles && (
          <div className="credit-offer__subtitles">
            {subtitles.map(subtitle => (
              <StyledLabel
                key={generateKeyToComponent(subtitle)}
                as="p"
                className="credit-offer__subtitle"
                {...subtitle}
              />
            ))}
          </div>
        )}
      </div>
    )}
  </div>
);

CreditOffer.propTypes = {
  image: shape({
    alt: string.isRequired,
    url: shape({
      src: string.isRequired,
    }),
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  title: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
  }).isRequired,
  subtitles: arrayOf(
    shape({
      text: string.isRequired,
      color: string,
      font_size: string,
      font_family: string,
    }),
  ),
};

export default CreditOffer;
