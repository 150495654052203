import React, { useEffect, useCallback } from 'react';
import { string, arrayOf, shape, oneOfType, func, boolean } from 'prop-types';
import Header from '../location/header';
import StaticMap from '../location/static-map';
import { renderMap } from '../location/render-map';
import PointsOfInterest from '../points-of-interest/points-of-interest.desktop';

const namespace = 'ui-vip-location';

const LocationAndPointsDesktop = ({
  title,
  contentRows,
  mapInfo,
  onScrollComponent,
  categories,
  subtitle,
  featureName,
  focus,
}) => {
  const ref = React.useRef();

  useEffect(() => {
    if (focus) {
      ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      setTimeout(() => {
        onScrollComponent(false);
      }, 1000);
    }
  }, [focus, onScrollComponent]);

  const locationMap = useCallback(() => renderMap(mapInfo, `${namespace}__map`, false), [mapInfo]);

  return (
    <div ref={ref} className={namespace} id={featureName}>
      <Header title={title} contentRows={contentRows} />
      <StaticMap mapInfo={mapInfo} renderMap={locationMap} />
      <PointsOfInterest subtitle={subtitle} categories={categories} />
    </div>
  );
};

LocationAndPointsDesktop.propTypes = {
  title: shape({
    color: string,
    font_family: string,
    font_size: string,
    text: string,
  }),
  contentRows: arrayOf(
    shape({
      title: oneOfType([
        shape({
          text: string.isRequired,
          text_icon: shape({
            id: string.isRequired,
          }),
        }),
        string,
      ]),
      icon: oneOfType([shape({}), string]),
    }),
  ).isRequired,
  mapInfo: shape({
    icon: shape({
      id: string,
      color: string,
    }).isRequired,
    location: shape({
      latitude: string,
      longitude: string,
    }).isRequired,
    image: shape({
      id: string,
      alt: string,
      url: shape({
        src: string,
        src2x: string,
      }).isRequired,
    }).isRequired,
    action: shape({
      track: shape({
        melidata_event: shape({
          path: string,
        }).isRequired,
        action_type: string,
      }),
    }),
    zoom: string.isRequired,
  }).isRequired,
  onScrollComponent: func,
  categories: arrayOf(shape({})).isRequired,
  subtitle: shape({
    color: string,
    font_family: string,
    font_size: string,
    text: string,
  }),
  featureName: string,
  focus: boolean,
};

LocationAndPointsDesktop.defaultProps = {
  onScrollComponent: null,
};

export default LocationAndPointsDesktop;
