import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import ExternalCredits from '../../../../components/external-credits/external-credits';
import jsonToProps from '../../../../components/external-credits/jsonToProps';

const FEATURE_NAME = 'external_credits';

const mapStateToProps = ({ components: { [FEATURE_NAME]: externalCredits }, id, recaptchaSiteKey }) => ({
  itemId: id,
  recaptchaSiteKey,
  ...jsonToProps(externalCredits),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(ExternalCredits);
