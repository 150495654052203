import React from 'react';
import { string, number, shape, arrayOf, func } from 'prop-types';
import classNames from 'classnames';
import { Pill } from '@andes/badge';
import Actions from '../buybox/actions/actions-buybox';
import StyledLabel from '../styled-label';
import componentEnhance from '../../lib/component-enhance';
import OfferedBy from './components/offered-by';
import { Recaptchav3 } from '../recaptcha';
import CreditOffer from '../credit-offer/credit-offer';

const namespace = 'ui-pdp-generic-brands-summary';

const FeatureTitle = ({ title, feature }) => (
  <div className={classNames(`${namespace}__feature`)}>
    <StyledLabel
      as="h2"
      text={title.text}
      color={title.color}
      font_size={title.font_size}
      font_family={title.font_family}
      className={`${namespace}__feature__title`}
    />
    {feature ? (
      <Pill className={`${namespace}__feature__tag`} color="accent">
        {feature.text}
      </Pill>
    ) : null}
  </div>
);

const ExternalCredits = ({
  title,
  feature,
  subtitles,
  action,
  offered_by,
  creditOffer,
  className,
  itemId,
  recaptchaSiteKey,
  legal_disclaimer,
}) => (
  <div className={classNames(namespace, className)}>
    <Recaptchav3 siteKey={recaptchaSiteKey} />
    <FeatureTitle title={title} feature={feature} />
    {creditOffer && <CreditOffer {...creditOffer} />}
    {subtitles && (
      <ul className={`${namespace}__subtitles`}>
        {subtitles.map(subtitle => (
          <li key={subtitle.label.text} className={`${namespace}__subtitles__subtitle`}>
            <StyledLabel
              as="p"
              color={subtitle.label.color}
              font_size={subtitle.label.font_size}
              font_family={subtitle.label.font_family}
              text={componentEnhance.jsx(subtitle.label.text, subtitle.label.values)}
            />
          </li>
        ))}
      </ul>
    )}
    {offered_by && <OfferedBy namespace={namespace} offered_by={offered_by} />}
    {legal_disclaimer && (
      <span className={`${namespace}__legal_disclaimer`}>
        <StyledLabel
          s="p"
          color={legal_disclaimer.color}
          text={componentEnhance.jsx(legal_disclaimer.text, legal_disclaimer.values)}
          font_size={legal_disclaimer.font_size}
          font_family={legal_disclaimer.font_family}
        />
      </span>
    )}
    {action && <Actions actions={[action]} itemId={itemId} recaptchaSiteKey={recaptchaSiteKey} />}
  </div>
);

FeatureTitle.propTypes = {
  title: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
  }),
  feature: shape({
    text: string,
    color: string,
    font_family: string,
    bg_color: string,
  }),
};

FeatureTitle.defaultProps = {
  title: null,
  feature: null,
};

Actions.propTypes = {
  type: string,
  target: string,
  onClick: func,
  label: shape({
    text: string.isRequired,
  }),
  track: shape({
    melidata_event: shape({
      path: string,
    }).isRequired,
    analytics_event: shape({
      action: string,
      category: string,
      label: string,
    }).isRequired,
    action_type: string,
  }),
};

Actions.defaultProps = {
  type: null,
  target: null,
  onClick: null,
  label: null,
  track: null,
};

ExternalCredits.propTypes = {
  itemId: string,
  className: string,
  recaptchaSiteKey: string,
  title: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
  }),
  feature: shape({
    text: string,
    color: string,
    font_family: string,
    bg_color: string,
  }),
  creditOffer: shape({
    image: shape({
      alt: string.isRequired,
      url: shape({
        src: string.isRequired,
      }),
      width: number.isRequired,
      height: number.isRequired,
    }).isRequired,
    title: shape({
      text: string.isRequired,
      color: string,
      font_size: string,
      font_family: string,
    }).isRequired,
    subtitles: arrayOf(
      shape({
        text: string.isRequired,
        color: string,
        font_size: string,
        font_family: string,
      }),
    ),
  }),
  legal_disclaimer: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
    values: shape({
      blue_text: shape({
        type: string,
        target: string,
        html_target: string,
        label: shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      }),
    }),
  }),
  subtitles: arrayOf(
    shape({
      text: string,
      values: shape({
        bold_text: shape({
          color: string,
          font_size: string,
          font_family: string,
        }),
      }),
    }),
  ),
  offered_by: shape({
    title: shape({
      picture: shape({
        id: string.isRequired,
        alt: string,
        url: shape({
          src: string,
        }),
        width: number,
        height: number,
      }),
      label: shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
    }),
    brands: arrayOf(
      shape({
        id: string.isRequired,
        alt: string,
        url: shape({
          src: string,
        }),
        width: number,
        height: number,
      }),
    ),
  }),
  action: shape({
    type: string,
    target: string,
    onClick: func,
    label: shape({
      text: string.isRequired,
    }).isRequired,
    track: shape({
      melidata_event: shape({
        path: string,
      }).isRequired,
      analytics_event: shape({
        action: string,
        category: string,
        label: string,
      }).isRequired,
      action_type: string,
    }),
  }),
};

ExternalCredits.defaultProps = {
  className: '',
  recaptchaSiteKey: null,
  title: null,
  feature: null,
  subtitles: null,
  offered_by: null,
  action: null,
  creditOffer: null,
  legal_disclaimer: null,
};

export default ExternalCredits;
