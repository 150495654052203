import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import jsonToProps from '../../../../components/message/jsonToProps';
import KYCNotification from '../../../../components/kyc-notification';

const FEATURE_NAME = 'kyc_motors_notification';

const mapStateToProps = ({ components: { [FEATURE_NAME]: kycNotification } }) => ({
  ...jsonToProps(kycNotification),
  actionsPosition: 'right',
  primaryAction: kycNotification.primary_action,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(KYCNotification);
