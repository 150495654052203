import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import PrintToPdf from '../../../../components/print-to-pdf/print-to-pdf';

const FEATURE_NAME = 'print_to_pdf';
const SHARE_FEATURE_NAME = 'share';

const mapStateToProps = ({ components: { [FEATURE_NAME]: printToPDF, [SHARE_FEATURE_NAME]: share } }, ownProps) => ({
  action: printToPDF.action,
  shouldShowSeparator: !!share && share.state === 'VISIBLE',
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

export default enhance(PrintToPdf);
