import React, { useEffect, useRef } from 'react';
import { shape, arrayOf, string, func, bool } from 'prop-types';
import { Tabs, Tab, TabContent } from '@andes/tabs';
import StyledLabel from '../styled-label';
import IconFactory from '../icons/factory-builder';
import Item from './item';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-vip-poi';

const getTitle = category => {
  const { title } = category;
  return (
    <div className={`${namespace}__title-container`}>
      {IconFactory(category.icon, `${namespace}__tab-icon`)}
      <span className={`${namespace}__tab-title`}>{title.text}</span>
    </div>
  );
};

const handleClickTab = (e, track) => {
  e.preventDefault();
  trackEvent(track);
};

const PointsOfInterest = ({ id, title, subtitle, categories, onScrollComponent, focus }) => {
  const ref = useRef(null);

  useEffect(() => {
    let timeoutId;

    if (focus) {
      window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });
      timeoutId = setTimeout(() => {
        onScrollComponent({ focus: false });
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [focus, onScrollComponent]);

  if (categories.length === 0) {
    return <></>;
  }

  return (
    <div ref={ref} id={id} className={namespace}>
      <StyledLabel as="h2" {...title} className={`${namespace}__title`} />
      <StyledLabel as="span" {...subtitle} className={`${namespace}__subtitle`} />
      <Tabs className={`${namespace}__tabs`} active={1}>
        {categories.map(category => (
          <Tab key={category.title.text} title={getTitle(category)} onClick={e => handleClickTab(e, category.track)}>
            <TabContent className={`${namespace}__tab-content`}>
              {category.subcategories.map(subcategory => (
                <div key={subcategory.title.text} className={`${namespace}__subsection`}>
                  <StyledLabel as="span" className={`${namespace}__subsection-title`} {...subcategory.title} />
                  {subcategory.items.map(item => (
                    <Item
                      {...item}
                      key={`${item.title.text}-${item.subtitle.label.text}`}
                      itemClass={`${namespace}__item`}
                      subtitleClass={`${namespace}__item-subtitle`}
                      titleClass={`${namespace}__item-title`}
                    />
                  ))}
                </div>
              ))}
            </TabContent>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

PointsOfInterest.propTypes = {
  id: string.isRequired,
  title: shape({}).isRequired,
  subtitle: shape({}).isRequired,
  categories: arrayOf(shape({})).isRequired,
  focus: bool,
  onScrollComponent: func,
};

PointsOfInterest.defaultProps = {
  focus: false,
  onScrollComponent: null,
};

export default React.memo(PointsOfInterest);
