import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip/vis';
import RequestModal from '../../../../components/request-modal';

const FEATURE_NAME = 'quotation_modal';

const mapStateToProps = (
  state,
  {
    className,
    itemId,
    modelId,
    deviceType,
    modalTitle,
    modalClassName,
    track,
    isLoginRequired,
    children,
    contentComponent,
    isOpen,
    onClose,
  },
) => ({
  id: state.id,
  className,
  itemId,
  modelId,
  deviceType,
  modalTitle,
  modalClassName,
  track,
  isFetching: !state.quotation,
  isOpenModal: isOpen,
  message_modal: state[FEATURE_NAME] && state[FEATURE_NAME].message,
  featureName: FEATURE_NAME,
  loginType: 'quotation',
  isLoginRequired,
  children,
  contentComponent,
  fetchParams: { modelId, deviceType },
  onClose,
  contentStep: state.quotation?.currentState?.contentStep,
});

const mapDispatchToProps = dispatch => ({
  fetchModalRequest: ({ modelId, deviceType }) => dispatch(actions.fetchUnregisteredQuotations(modelId, deviceType)),
  updateCurrentState: currentState => {
    dispatch(actions.updateQuotationCurrentState(currentState));
  },
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(RequestModal);
export { mapDispatchToProps };
