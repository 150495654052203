import isEmpty from 'lodash/isEmpty';
import { isSupportedByDefault } from '../../lib/local-storage';

const getForm = localStorageKey => {
  const formDataString = localStorage.getItem(localStorageKey);
  if (isEmpty(formDataString)) {
    return {};
  }
  let formDataObject;
  try {
    formDataObject = JSON.parse(formDataString);
  } catch (e) {
    formDataObject = {};
  }
  return formDataObject;
};

const saveFormField = (localStorageKey, key, value) => {
  const formDataObject = getForm(localStorageKey);
  formDataObject[`${key}`] = value;
  localStorage.setItem(localStorageKey, JSON.stringify(formDataObject));
};

const saveForm = (localStorageKey, form, fields) => {
  const isLocalStorageSupported = isSupportedByDefault();
  if (isLocalStorageSupported) {
    fields.forEach(field => saveFormField(localStorageKey, field, form[`${field}`].value || ''));
  }
};

export { saveForm, getForm };
