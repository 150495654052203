import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'security_tips';

const mapStateToProps = ({ components: { [FEATURE_NAME]: securityTips } }) => ({
  ...securityTips,
  header: securityTips.header.text,
  action: securityTips.actions[0],
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const securityTips = SecurityTips => enhance(SecurityTips);

export default securityTips;
