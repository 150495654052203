import React from 'react';
import { arrayOf, number, string } from 'prop-types';
import classnames from 'classnames';
import DotsNavigation from '../dots-navigation';

const namespace = 'pswp';

/* @TODO add translations to backend */
const LightboxTemplate = React.forwardRef((props, ref) => {
  const { className, index, shapes } = props;
  return (
    <div
      className={classnames(namespace, className)}
      data-testid="pswp"
      ref={ref}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="pswp__bg" />
      <div className="pswp__scroll-wrap">
        <div className="pswp__container">
          <div className="pswp__item" />
          <div className="pswp__item" />
          <div className="pswp__item" />
        </div>
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            <div className="pswp__counter" />
            <button type="button" className="pswp__button pswp__button--close" title="Close (Esc)" />
            <button type="button" className="pswp__button pswp__button--share" title="Share" />
            <button type="button" className="pswp__button pswp__button--fs" title="Toggle fullscreen" />
            <button type="button" className="pswp__button pswp__button--zoom" title="Zoom in/out" />
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut" />
                </div>
              </div>
            </div>
          </div>
          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip" />
          </div>
          <button type="button" className="pswp__button pswp__button--arrow--left" title="Previous (arrow left)" />
          <button type="button" className="pswp__button pswp__button--arrow--right" title="Next (arrow right)" />
          <div className="pswp__caption">
            <div className="pswp__caption__center" />
          </div>
        </div>
      </div>
      {shapes?.length > 1 && <DotsNavigation index={index} shapes={shapes} theme="dark" />}
    </div>
  );
});

LightboxTemplate.propTypes = {
  className: string,
  index: number,
  shapes: arrayOf(string),
};

LightboxTemplate.defaultProps = {
  className: null,
};

export default LightboxTemplate;
