import React, { useRef, useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import Image from '../image';
import IconFactory from '../icons/factory-builder';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-vip-location';
const intersectionRatio = 0.89;

const handleClickImage = (renderMap, mapInfo) => {
  if (renderMap) {
    renderMap();
  }

  trackEvent(mapInfo.action.track);
};

const StaticMap = ({ mapInfo, renderMap }) => {
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry.intersectionRatio >= intersectionRatio &&
          document.getElementsByClassName(`${namespace}__map-spotlight`)
        ) {
          document.getElementsByClassName(`${namespace}__map-spotlight`)[0].classList.add('bounce');
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.89,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  return (
    <div
      data-testid="container-map"
      id={`${namespace}__map`}
      ref={ref}
      className={`${namespace}__map`}
      aria-hidden="true"
    >
      <div
        data-testid="container-static-map"
        onClick={() => handleClickImage(renderMap, mapInfo)}
        role="button"
        aria-hidden="true"
      >
        {IconFactory(mapInfo.icon, `${namespace}__map-spotlight`)}
        <Image
          id={mapInfo.image.url.src}
          data-testid="static-map"
          lazyload={false}
          alt={mapInfo.image.alt}
          src2x={mapInfo.image.url.src2x}
          src={mapInfo.image.url.src}
        />
      </div>
    </div>
  );
};

StaticMap.propTypes = {
  mapInfo: shape({
    icon: shape({
      id: string,
      color: string,
    }).isRequired,
    location: shape({
      latitude: string,
      longitude: string,
    }).isRequired,
    image: shape({
      id: string,
      alt: string,
      url: shape({
        src: string,
        src2x: string,
      }).isRequired,
    }).isRequired,
    action: shape({
      track: shape({}),
    }),
    zoom: string.isRequired,
  }).isRequired,
  renderMap: func,
};

StaticMap.defaultProps = {
  renderMap: null,
};

export default StaticMap;
