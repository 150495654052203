import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/report-problem/json-to-props';

const FEATURE_NAME = 'report_problem';

const mapStateToPropsReport = ({ components: { [FEATURE_NAME]: reportProblem } }) => ({
  ...jsonToProps(reportProblem),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToPropsReport),
);

export default enhance;
