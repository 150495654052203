const DEFAULT_SETTINGS = {
  captionEl: false,
  fullscreenEl: false,
  zoomEl: false,
  shareEl: false,
  arrowEl: true,
  bgOpacity: 0.8,
  showAnimationDuration: 0,
  hideAnimationDuration: 0,
};
const CLICK_PROPS_TYPE = ['touch', 'mouse'];

export { CLICK_PROPS_TYPE, DEFAULT_SETTINGS };
