import React from 'react';
import { string, arrayOf, shape, oneOfType, func } from 'prop-types';
import classnames from 'classnames';
import StyledLabel from '../styled-label';
import Media from '../media';
import colornames from '../../lib/colornames';
import MediaAction from '../media/media-action';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-vip-location';

const onClickHandler = onScrollComponent => onScrollComponent && onScrollComponent({ focus: true });

const LocationHeader = ({ title, contentRows, onScrollComponent }) => (
  <>
    <StyledLabel as="h2" className={`${namespace}__title`} {...title} />
    {contentRows.map(row => (
      <Media
        key={row.title.text}
        className={classnames(`${namespace}__subtitle`, colornames(row.icon))}
        title={row.title}
        figure={IconFactory(row.icon)}
      >
        {row.action && (
          <MediaAction
            data-testid="point-of-interest-link"
            className={`${namespace}__poi-link`}
            label={row.action.label}
            onClick={() => onClickHandler(onScrollComponent)}
          />
        )}
      </Media>
    ))}
  </>
);

LocationHeader.propTypes = {
  title: shape({}).isRequired,
  contentRows: arrayOf(
    shape({
      title: oneOfType([
        shape({
          text: string.isRequired,
          values: shape({}),
          text_icon: shape({
            id: string.isRequired,
          }),
        }),
        string,
      ]),
      icon: oneOfType([shape({}), string]),
      action: shape({
        target: string,
        label: shape({}),
        track: shape({
          melidata_event: shape({}),
          analytics_event: shape({}),
        }),
      }),
    }),
  ).isRequired,
  onScrollComponent: func,
};

LocationHeader.defaultProps = {
  onScrollComponent: null,
};

export default LocationHeader;
