import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip/vis';
import * as actionsVip from '../../../../actions/vip';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import GroupedMainActions from '../../../../components/buybox/actions/container-actions';
import jsonToProps from '../../../../components/buybox/actions/jsonToProps';

const FEATURE_NAME = 'grouped_main_actions';

const mapStateToProps = ({ [FEATURE_NAME]: groupedMainActions, component, doContactAction, recaptchaSiteKey }) => ({
  ...jsonToProps(groupedMainActions),
  isFetchingData: groupedMainActions.isFetchingData,
  message: groupedMainActions.message,
  recaptchaPublicKey: groupedMainActions.recaptchaPublicKey,
  containerActions: groupedMainActions.container_actions,
  areActionsSticky: groupedMainActions.are_actions_sticky,
  responseToken: groupedMainActions.responseToken,
  recaptchaSiteKey,
  component,
  doContactAction,
  featureName: FEATURE_NAME,
});

const mapDispatchToProps = dispatch => ({
  fetchContactInfo: ({
    itemId,
    contactType,
    recaptchav2token,
    message,
    action,
    ajaxBackendParams,
    recaptchaSiteKey,
    params,
  }) =>
    dispatch(
      actions.fetchContactInfo({
        component: FEATURE_NAME,
        itemId,
        contactType,
        recaptchav2token,
        message,
        action,
        ajaxBackendParams,
        recaptchaSiteKey,
        params,
      }),
    ),

  redirectToLogin: (itemId, featureName, loginType) =>
    dispatch(actions.redirectToLogin({ itemId, featureName, loginType })),
  onRequestFocus: ({ component, focus }) => dispatch(actions.onScrollComponent({ component, focus })),
  updateTokenResponse: ({ token }) => dispatch(actions.updateTokenResponse({ component: FEATURE_NAME, token })),
  updateBudgetComponents: message => dispatch(actions.updateBudgetComponents(message)),
  showSnackbar: props => dispatch(actionsVip.showSnackbar({ ...props })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(GroupedMainActions);
export { mapStateToProps, mapDispatchToProps };
