import React from 'react';
import { string, arrayOf, shape, oneOfType, func } from 'prop-types';
import Header from './header';
import StaticMap from './static-map';
import { renderMap } from './render-map';

const namespace = 'ui-vip-location';

const Location = ({ title, contentRows, mapInfo, onScrollComponent }) => (
  <div className={namespace}>
    <Header title={title} contentRows={contentRows} onScrollComponent={onScrollComponent} />
    <StaticMap mapInfo={mapInfo} renderMap={() => renderMap(mapInfo, `${namespace}__map`, false)} />
  </div>
);

Location.propTypes = {
  title: shape({}).isRequired,
  contentRows: arrayOf(
    shape({
      title: oneOfType([
        shape({
          text: string.isRequired,
          values: shape({}),
          text_icon: shape({
            id: string.isRequired,
          }),
        }),
        string,
      ]),
      icon: oneOfType([shape({}), string]),
    }),
  ).isRequired,
  mapInfo: shape({
    icon: shape({
      id: string,
      color: string,
    }).isRequired,
    location: shape({
      latitude: string,
      longitude: string,
    }).isRequired,
    image: shape({
      id: string,
      alt: string,
      url: shape({
        src: string,
        src2x: string,
      }).isRequired,
    }).isRequired,
    action: shape({
      track: shape({}),
    }),
    zoom: string.isRequired,
  }).isRequired,
  onScrollComponent: func,
};

Location.defaultProps = {
  onScrollComponent: null,
};

export default Location;
