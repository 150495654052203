import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'long_term_rental_requirements';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => ({ ...props });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const requirementsLTR = RequirementsLTR => enhance(RequirementsLTR);

export default requirementsLTR;
