import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/online-rental-details/json-to-props';

const FEATURE_NAME = 'online_rental_details';

const mapStateToProps = ({ id, components: { [FEATURE_NAME]: onlineRentalDetails } }) => ({
  ...jsonToProps(onlineRentalDetails),
  itemId: id,
  onRequestFocus: () => {},
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance;
