import React, { Fragment, useState, useEffect } from 'react';
import { arrayOf, shape, string, func, node, oneOf, number, bool } from 'prop-types';
import ActionBuyBox from './actions-buybox';
import { Recaptchav2, Recaptchav3 } from '../../recaptcha';
import { generateKeyToComponent } from '../../../utils/validators';

const DEFAULT_DELAY_TIME = 3000;

const ContainerActions = ({
  containerActions,
  itemId,
  recaptchaPublicKey,
  recaptchaSiteKey,
  component,
  onRequestFocus,
  messages,
  responseToken,
  updateTokenResponse,
  areActionsSticky,
  showSnackbar,
  ...props
}) => {
  const [tokenResponseV2, setTokenResponseV2] = useState(responseToken);
  const [shouldShowSnackbar, setShouldShowSnackbar] = useState(messages && messages.text);

  const getStickyClasses = () => {
    if (areActionsSticky) {
      return '-sticky';
    }
    return '';
  };

  const onFocusHandler = (event, target, focus) => {
    onRequestFocus({ component: target, focus });
  };

  if (shouldShowSnackbar) {
    showSnackbar({
      message: messages.text,
      type: messages.type,
      delay: DEFAULT_DELAY_TIME,
    });
    setShouldShowSnackbar(false);
  }

  useEffect(() => {
    setShouldShowSnackbar(messages && messages.text);
  }, [messages]);

  useEffect(() => {
    setTokenResponseV2(responseToken);
  }, [responseToken]);

  return (
    <>
      {containerActions.length > 0 && (
        <div className={`ui-pdp-container-actions${getStickyClasses()}`}>
          {containerActions.map(containerAction => (
            <Fragment key={`${generateKeyToComponent(containerAction)}-${itemId}`}>
              {recaptchaPublicKey && (
                <Recaptchav2
                  align="center"
                  recaptchaPublicKey={recaptchaPublicKey}
                  required
                  lowEnd
                  id="contact-info-recaptcha"
                  verifyCallback={token => {
                    setTokenResponseV2(token);
                    if (updateTokenResponse) {
                      updateTokenResponse({ token });
                    }
                  }}
                />
              )}
              <ActionBuyBox
                itemId={itemId}
                actions={containerAction.actions}
                recaptchav2token={tokenResponseV2}
                recaptchaSiteKey={recaptchaSiteKey}
                onClick={onFocusHandler}
                {...props}
              />
            </Fragment>
          ))}
          <Recaptchav3 siteKey={recaptchaSiteKey} />
        </div>
      )}
    </>
  );
};

ContainerActions.propTypes = {
  containerActions: arrayOf(
    shape({
      actions: arrayOf(
        shape({
          label: shape({
            text: string.isRequired,
            color: string,
          }).isRequired,
          type: string.isRequired,
          target: string,
          track: shape({}),
        }),
      ).isRequired,
    }),
  ),
  itemId: string.isRequired,
  recaptchaPublicKey: string,
  recaptchaSiteKey: string,
  component: node,
  onRequestFocus: func.isRequired,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
    key: number,
  }),
  responseToken: string,
  updateTokenResponse: func,
  updateBudgetComponents: func,
  areActionsSticky: bool,
  showSnackbar: func,
};

ContainerActions.defaultProps = {
  containerActions: [],
  recaptchaPublicKey: null,
  recaptchaSiteKey: null,
  component: null,
  messages: null,
  responseToken: null,
  updateTokenResponse: null,
  updateBudgetComponents: null,
  areActionsSticky: false,
  showSnackbar: null,
};

export default React.memo(ContainerActions);
