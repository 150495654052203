/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { bool, func, shape, string, node } from 'prop-types';
import classNames from 'classnames';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';
import { Modal } from '@andes/modal';
import { Snackbar } from '@andes/snackbar';
import { forEach } from '../../lib/dom-utils';
import ModalContent from './modal-content';
import { trackEvent } from '../../lib/tracking';
import { validateSnackbarColor } from '../../utils/validators';

const DEFAULT_DELAY_TIME = 7000;

const namespace = 'ui-vip-request-modal';

const RequestModal = ({
  className,
  modalTitle,
  modalClassName,
  itemId,
  loginType,
  featureName,
  redirectToLogin,
  isLoginRequired,
  fetchModalRequest,
  fetchParams,
  deviceType,
  track,
  isOpenModal,
  isFetching,
  message_modal,
  modalResizing,
  children,
  contentComponent,
  onClose,
  updateCurrentState,
  disabled,
  closeModalLabel,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [componentIsMounted, setComponentIsMounted] = useState(false);

  useEffect(() => {
    if (showModal) {
      setShowLoading(isFetching);
      setComponentIsMounted(!isFetching);
    }
  }, [showModal, showLoading, isFetching, isOpenModal]);

  const openModal = e => {
    if (!e || (!e.ctrlKey && !e.metaKey)) {
      if (e) {
        e.preventDefault();
      }
      if (deviceType !== 'mobile') {
        // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
        // dejado por la scrollbar en los casos que se utilice mouse
        // checks para omitir el test del dom
        const main = document.querySelectorAll('body > header, body > main') || [];

        forEach(main, element => {
          element.classList.add('with-scroll-bars-hidden');
        });
      }
      if (track) {
        trackEvent(track);
      }
      setShowLoading(isFetching);
      if (isLoginRequired && redirectToLogin) {
        redirectToLogin(itemId, loginType, featureName);
        setShowModal(false);
      } else {
        fetchModalRequest(fetchParams);
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      openModal();
    }
  }, [isOpenModal]);

  const hideModal = () => {
    if (updateCurrentState) {
      updateCurrentState({});
    }
    if (onClose) {
      onClose();
    }
    if (deviceType !== 'mobile') {
      // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
      // dejado por la scrollbar en los casos que se utilice mouse
      const main = document.querySelectorAll('body > header, body > main') || [];

      forEach(main, element => {
        element.classList.remove('with-scroll-bars-hidden');
      });
    }
    setShowModal(false);
    setShowLoading(false);
    setComponentIsMounted(false);
  };

  return (
    <div className={classNames(namespace, className)}>
      {children && (
        <div
          className={`${namespace}__button`}
          data-testid="button-modal"
          onClick={!disabled ? openModal : null}
          onKeyPress={!disabled ? openModal : null}
          role="button"
          tabIndex="0"
        >
          {children}
        </div>
      )}
      <Modal
        title={modalTitle}
        className={classNames(`ui-pdp-action-modal`, `${namespace}__modal`, modalClassName)}
        open={showModal}
        onClose={hideModal}
        closeButtonSrLabel={closeModalLabel}
        type={deviceType === 'mobile' ? 'full' : 'large'}
        maxWidth={1000}
      >
        {!isLoginRequired &&
          (showLoading && (
            <ProgressIndicatorCircular className={`${namespace}__spinner`} modifier="fullscreen" size="large" />
          ))}
        <ModalContent modalClassName={modalClassName} componentIsMounted={componentIsMounted} resizing={modalResizing}>
          {contentComponent}
        </ModalContent>
      </Modal>
      {message_modal && message_modal.text && (
        <Snackbar
          delay={DEFAULT_DELAY_TIME}
          message={message_modal.text}
          color={validateSnackbarColor(message_modal.type)}
          show
        />
      )}
    </div>
  );
};

RequestModal.propTypes = {
  className: string,
  modalTitle: string,
  modalClassName: string.isRequired,
  itemId: string.isRequired,
  fetchModalRequest: func.isRequired,
  fetchParams: shape({}),
  deviceType: string,
  track: shape({}),
  isFetching: bool.isRequired,
  isOpenModal: bool,
  message_modal: shape({ text: string, type: string }),
  modalResizing: bool,
  loginType: string,
  featureName: string,
  redirectToLogin: func,
  isLoginRequired: bool,
  children: node,
  contentComponent: node.isRequired,
  onClose: func,
  updateCurrentState: func,
  disabled: bool,
  closeModalLabel: string,
};

RequestModal.defaultProps = {
  className: null,
  deviceType: null,
  modalTitle: ' ',
  message_modal: null,
  isOpenModal: false,
  modalResizing: null,
  loginType: null,
  featureName: null,
  redirectToLogin: null,
  isLoginRequired: false,
  fetchParams: null,
  children: null,
  onClose: null,
  track: null,
  updateCurrentState: null,
  disabled: false,
};

export default React.memo(RequestModal);
