/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Image } from 'nordic/image';
import classnames from 'classnames';
import { constants } from '../../utils/constants';

const namespace = 'clip-wrapper';

const getVideoControlClassName = (videoControl, hide = true) =>
  classnames(`${namespace}__${videoControl}`, { [`${namespace}__${videoControl}--hide`]: hide });

const ClipMultimedia = props => {
  const { DEVICE_TYPE } = constants;
  const { autoplay, className, deviceType, id, onClick, onFullscreen, onMute, thumbnail, videoContainer } = props;
  const wrapperClassName = classnames(namespace, className);

  const customOnClick = (...args) => {
    onClick(...args);
  };
  return (
    <section className={wrapperClassName}>
      <div
        className={`${namespace}__glass-screen`}
        data-testid={`test:${namespace}__glass-screen`}
        onClick={customOnClick}
      />
      <Image className={`${namespace}__thumbnail`} alt={thumbnail?.alt} lazyload="off" src={thumbnail?.src} />
      <i className={getVideoControlClassName('play', deviceType === DEVICE_TYPE.MOBILE && autoplay)} />
      <i className={getVideoControlClassName('big-mute', !(deviceType === DEVICE_TYPE.MOBILE && autoplay))} />
      <i className={getVideoControlClassName('pause')} />
      <i className={getVideoControlClassName('spinner')} />
      <i
        className={getVideoControlClassName('muted')}
        data-testid={`test:${namespace}__muted`}
        onClick={() => onMute(true)}
      />
      <i
        className={getVideoControlClassName('unmuted')}
        data-testid={`test:${namespace}__unmuted`}
        onClick={() => onMute(false)}
      />
      <i
        className={getVideoControlClassName('fullscreen')}
        data-testid={`test:${namespace}__fullscreen`}
        onClick={onFullscreen}
      />
      <div className={getVideoControlClassName('shadow')} />
      <div className="video-container" id={`${videoContainer}-${id}`} />
    </section>
  );
};

ClipMultimedia.propTypes = {
  autoplay: bool,
  className: string,
  deviceType: string,
  id: string.isRequired,
  onClick: func,
  onFullscreen: func,
  onMute: func,
  thumbnail: shape({
    src: string,
    alt: string,
  }),
  videoContainer: string,
};

ClipMultimedia.defaultProps = {
  autoplay: false,
  className: '',
  deviceType: constants.DEVICE_TYPE.DESKTOP,
  onClick: () => {},
  onFullscreen: () => {},
  onMute: () => {},
  thumbnail: {
    src: '',
    alt: '',
  },
  videoContainer: 'video-container',
};

export default ClipMultimedia;
