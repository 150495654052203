import React from 'react';
import { bool, string, shape } from 'prop-types';
import { MessageActions } from '@andes/message';
import Message from '../message';
import { trackEvent } from '../../lib/tracking';

const KYCNotification = ({ text, closeable, hierarchy, actionsPosition, primaryAction }) => {
  const primaryProps = {
    onClick: () => {
      trackEvent(primaryAction.track);
      if (primaryAction.target) {
        window.location.href = primaryAction.target;
      }
    },
    text: primaryAction.label.text,
  };
  return (
    <div className="ui-vip-kyc-notification">
      <Message
        text={text}
        closeable={closeable}
        hierarchy={hierarchy}
        actionsPosition={actionsPosition}
        actions={<MessageActions primary={primaryProps} />}
      />
    </div>
  );
};

KYCNotification.propTypes = {
  text: string.isRequired,
  closeable: bool,
  hierarchy: string,
  actionsPosition: string,
  primaryAction: shape({
    label: shape({
      text: string,
    }),
    target: string,
    track: shape({}),
  }).isRequired,
};

KYCNotification.defaultProps = {
  closeable: false,
  hierarchy: 'loud',
  actionsPosition: 'bottom',
};

export default KYCNotification;
