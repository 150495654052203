import React from 'react';
import { string } from 'prop-types';
import Image from '../image';

const PiStatistics = ({ url }) => <Image src={url} alt="" height="1" width="1" onload="on" />;

PiStatistics.propTypes = {
  url: string,
};

PiStatistics.defaultProps = {
  url: string,
};

export default React.memo(PiStatistics);
