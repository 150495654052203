import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import jsonToProps from '../../../../components/message/jsonToProps';
import Message from '../../../../components/message';

const FEATURE_NAME = 'quotation_limit_message';

const mapStateToProps = ({ components: { [FEATURE_NAME]: message } }) =>
  jsonToProps({ ...message, className: 'ui-pdp-quotation-limit-message' });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Message);
