const getClipWrapper = (videoContainer, id) => {
  const videoId = `${videoContainer}-${id}`;
  const wrappers = Array.from(document.getElementsByClassName('clip-wrapper'));
  const wrapperWithClip = wrappers.find(wrapper =>
    Array.from(wrapper?.childNodes)?.find(child => child.id === videoId),
  );

  return wrapperWithClip;
};

const toggleClipVisibility = (clipWrapperEl, type, show) => {
  const clipElementClass = `clip-wrapper__${type}`;
  const clipHideClass = `${clipElementClass}--hide`;
  const clipElement = clipWrapperEl?.querySelector(`.${clipElementClass}`);

  if (typeof show === 'undefined') {
    clipElement?.classList?.toggle(clipHideClass);
  } else if (show) {
    clipElement?.classList?.remove(clipHideClass);
  } else {
    clipElement?.classList?.add(clipHideClass);
  }
};

const toggleClipElement = (videoContainer, videoId, types, show) => {
  const clipWrapperEl = getClipWrapper(videoContainer, videoId);

  if (!types?.length && !clipWrapperEl) {
    return;
  }

  if (Array.isArray(types)) {
    types.forEach(type => toggleClipVisibility(clipWrapperEl, type, show));
  } else {
    toggleClipVisibility(clipWrapperEl, types, show);
  }
};

export { getClipWrapper, toggleClipElement };
