import React, { useEffect } from 'react';
import { string, arrayOf, func, shape, number, bool, oneOfType, object } from 'prop-types';
import Tooltip from '@andes/tooltip';
import StyledLabel from '../styled-label';
import Chart from '../chart-component/chart';
import ActionRow from '../action-row';
import MediaAction from '../media/media-action';
import Message from '../message';
import componentEnhance from '../../lib/component-enhance';
import { trackEvent } from '../../lib/tracking';
import IconFactory from '../icons/factory-builder';
import { FeatureFeedback } from '../feature-feedback';
import { generateKeyToComponent } from '../../utils/validators';

const namespace = 'ui-pdp-price-comparison';
const paddingChart = 48;

const SuggestedPriceChart = ({ namespace: namespaceChart, chart, legend, actions }) => {
  const action = actions && actions[0];
  const pricesDistribution = chart.price_distributions || [];
  const barsHoverInfo = chart.hover_info || [];
  const lowerBand = chart.lower_band.text || '';
  const middleBand = chart.middle_band ? chart.middle_band.text : '';
  const upperBand = chart.upper_band.text || '';
  const { tooltip } = chart;
  const tooltipFipe = chart.tooltip_fipe;

  const chartProps = {
    pricesDistribution,
    barsHoverInfo,
    lowerBand,
    middleBand,
    upperBand,
    tooltip,
    tooltipFipe,
    padding: paddingChart,
  };

  return (
    <>
      <Chart className={`${namespaceChart}__chart`} {...chartProps} />
      {legend && (
        <div className={`${namespaceChart}__legend`}>
          {legend.title && (
            <StyledLabel
              className={`${namespaceChart}__title-legend`}
              as="h2"
              color={legend.title.color}
              font_family={legend.font_family}
              font_size={legend.title.font_size}
              text={legend.title.text || ''}
            />
          )}
          <div className={`${namespaceChart}__subtitle-legend`}>
            <StyledLabel
              font_family={legend.subtitle.font_family}
              font_size={legend.subtitle.font_size}
              color={legend.subtitle.color}
              text={componentEnhance.jsx(legend.subtitle.text, legend.subtitle.values)}
            />
          </div>
        </div>
      )}
      {action && (
        <ActionRow
          className={`${namespaceChart}__action`}
          modifier="secondary"
          label={action.label.text}
          url={action.target}
          onClick={() => trackEvent(action.track)}
        />
      )}
    </>
  );
};

const SuggestedPricePills = ({ namespace: namespacePills, pills }) => (
  <div className={`${namespacePills}__pills`}>
    {pills.map(pill => (
      <div key={generateKeyToComponent(pill)} className={`${namespacePills}__pill`}>
        <StyledLabel className={`${namespacePills}__pills-title`} {...pill.title} />
        <div className={`${namespacePills}__pills-subtitle`}>
          <StyledLabel {...pill.subtitle} />
          {pill.icon && IconFactory(pill.icon)}
        </div>
      </div>
    ))}
  </div>
);

const PriceComparison = ({
  title,
  header_title,
  header_title_tooltip,
  extra_info,
  inner_action,
  chart,
  legend,
  actions,
  free_list_message,
  pills,
  focus,
  onScrollComponent,
  feedback_component,
}) => {
  const ref = React.useRef();

  useEffect(() => {
    let timeoutId;

    if (focus) {
      ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      timeoutId = setTimeout(() => {
        onScrollComponent({ focus: false });
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [focus, onScrollComponent]);

  return (
    <div ref={ref} className={namespace}>
      <StyledLabel
        className={`${namespace}__title`}
        as="h2"
        color={title.color}
        font_family={title.font_family}
        font_size={title.font_size}
        text={title.text || ''}
      />

      {header_title && (
        <StyledLabel
          className={`${namespace}__subtitle`}
          color={header_title.color}
          font_family={header_title.font_family}
          font_size={header_title.font_size}
          text={componentEnhance.jsx(header_title.text, header_title.values)}
        />
      )}
      {header_title_tooltip && (
        <div className={`${namespace}__subtitle-with-tooltip`}>
          <div>
            <StyledLabel className={`${namespace}__subtitle`} {...header_title_tooltip.title} />
          </div>
          <div className={`${namespace}__subtitle-tooltip-wrapper`}>
            <Tooltip
              className={`${namespace}__subtitle-tooltip`}
              content={<StyledLabel className={`${namespace}__subtitle`} {...header_title_tooltip.message.body} />}
              side="top"
              trigger="click"
              offsetX={8}
              offsetY={-16}
            >
              {IconFactory(header_title_tooltip.icon)}
            </Tooltip>
          </div>
        </div>
      )}
      {chart && <SuggestedPriceChart namespace={namespace} chart={chart} legend={legend} actions={actions} />}
      {pills && <SuggestedPricePills namespace={namespace} pills={pills} />}
      {free_list_message && (
        <Message
          className={`${namespace}__message`}
          {...{
            closeable: free_list_message.closeable,
            hierarchy: free_list_message.hierarchy,
            type: free_list_message.message_type,
          }}
        >
          <div className={`${namespace}__message__text`}>
            <StyledLabel key={free_list_message.subtitle.text} as="span" {...free_list_message.subtitle} />
            <br />
            <a
              data-testid="free-list-ad-link"
              target={free_list_message.link.html_target}
              href={free_list_message.link.target}
              onClick={() => trackEvent(free_list_message.link.track)}
            >
              {free_list_message.link.label.text}
            </a>
          </div>
        </Message>
      )}
      {inner_action && <MediaAction {...inner_action} />}
      {extra_info && (
        <div className={`${namespace}__extra-info-wrapper`}>
          <StyledLabel className={`${namespace}__extra-info-title`} {...extra_info.title} />
          {extra_info.paragraph.map(item => (
            <div key={item.title.text} className={`${namespace}__extra-info-elements`}>
              <StyledLabel className={`${namespace}__extra-info-element-title`} {...item.title} />
              <StyledLabel className={`${namespace}__extra-info-element-value`} {...item.value} />
              <div className={`${namespace}__extra-info-elements-row-cleaner`} />
            </div>
          ))}
        </div>
      )}
      {feedback_component && <FeatureFeedback {...feedback_component} />}
    </div>
  );
};

PriceComparison.propTypes = {
  title: shape({
    text: string,
    font_size: string,
    color: string,
    font_family: string,
  }).isRequired,
  header_title: oneOfType([
    shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
    shape({
      text: string,
      values: shape({}),
      font_size: string,
      color: string,
      font_family: string,
    }),
  ]),
  header_title_tooltip: shape({
    title: shape({}),
    message: shape({
      body: shape({}),
    }),
  }),
  extra_info: shape({
    title: shape({}),
    paragraph: arrayOf(
      shape({
        title: shape({}),
        value: shape({}),
      }),
    ),
  }),
  inner_action: shape({}),
  chart: shape({
    lower_band: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
    upper_band: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
    price_distributions: arrayOf(number),
    tooltip: shape({
      data: shape({
        text: string,
        font_size: string,
        color: string,
        font_family: string,
        bg_color: string,
      }),
      percentile_position: number,
    }),
  }),
  legend: shape({
    title: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
    subtitle: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
  }),
  actions: arrayOf(
    shape({
      html_target: string,
      label: shape({
        text: string,
        font_size: string,
        color: string,
        font_family: string,
      }),
      action_type: string,
      track: shape({}),
    }),
  ),
  pills: arrayOf(
    shape({
      title: {
        text: string,
        font_size: string,
        color: string,
        font_family: string,
      },
      subtitle: {
        text: string,
        font_size: string,
        color: string,
        font_family: string,
      },
      icon: shape({
        id: string.isRequired,
        color: string,
      }),
    }),
  ),
  free_list_message: shape({
    state: string,
    subtitle: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }).isRequired,
    link: shape({
      html_target: string,
      label: shape({
        text: string,
        font_size: string,
        color: string,
        font_family: string,
      }).isRequired,
      action_type: string,
    }).isRequired,
    closeable: bool,
    hierarchy: string,
    message_type: string,
  }),
  onScrollComponent: func,
  focus: bool,
  feedback_component: {
    local_storage_key: string.isRequired,
    title: shape({
      text: string,
    }).isRequired,
    actions: arrayOf(
      shape({
        id: string.isRequired,
        icon: string.isRequired,
        text: string.isRequired,
        congrats: shape({
          text: string.isRequired,
        }),
        // eslint-disable-next-line react/forbid-prop-types
        track: object,
      }).isRequired,
    ).isRequired,
  },
};

PriceComparison.defaultProps = {
  header_title_tooltip: null,
  extra_info: null,
  inner_action: null,
  chart: null,
  legend: null,
  actions: [],
  pills: null,
  free_list_message: null,
  focus: false,
  onScrollComponent: null,
  header_title: null,
};

SuggestedPriceChart.propTypes = {
  namespace: string,
  chart: shape({
    lower_band: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
    upper_band: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
    price_distributions: arrayOf(number),
    tooltip: shape({
      data: shape({
        text: string,
        font_size: string,
        color: string,
        font_family: string,
        bg_color: string,
      }),
      percentile_position: number,
    }),
    tooltip_fipe: shape({
      data: shape({
        text: string,
        font_size: string,
        color: string,
        font_family: string,
        bg_color: string,
      }),
      percentile_position: number,
    }),
  }),
  legend: shape({
    title: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
    subtitle: shape({
      text: string,
      font_size: string,
      color: string,
      font_family: string,
    }),
  }),
  actions: arrayOf(
    shape({
      html_target: string,
      label: shape({
        text: string,
        font_size: string,
        color: string,
        font_family: string,
      }),
      action_type: string,
      track: shape({}),
    }),
  ),
};

SuggestedPriceChart.defaultProps = {
  chart: null,
  legend: null,
  actions: null,
  namespace: '',
};

SuggestedPricePills.propTypes = {
  namespace: string,
  pills: arrayOf(
    shape({
      title: {
        text: string,
        font_size: string,
        color: string,
        font_family: string,
      },
      subtitle: {
        text: string,
        font_size: string,
        color: string,
        font_family: string,
      },
      icon: shape({
        id: string.isRequired,
        color: string,
      }),
    }),
  ),
};

SuggestedPricePills.defaultProps = {
  namespace: '',
  pills: null,
};

export default React.memo(PriceComparison);
