import React, { useState, useContext, useEffect } from 'react';
import { bool, shape, string, func } from 'prop-types';
import StaticPropsContext from '../../../context/static-props';
import { getForm } from '../../../../form/local-storage/unregistered-quotation';
import UnregisteredWithoutTabsDesktop from '../desktop/unregistered-without-tabs.desktop';
import UnregisteredWithoutTabsMobile from '../mobile/unregistered-without-tabs.mobile';
import { PRE_STEPS, IS_POSTING } from '../../utils/constants';

const localStorageKey = 'unregistered-quotation';

const { PRELOGIN, SHIELD, ACCESS } = PRE_STEPS;

const isObjectEmpty = values => Object.keys(values).length === 0;

const UnregisteredWithoutTabs = ({
  form,
  updateForm,
  onSubmit,
  messages,
  recaptchaPublicKey,
  url,
  isPostingQuotation,
  stepBack,
  selectedUnit,
  selection_info,
  selectedModel,
  preLogin,
  preUnregistered,
  preStep,
  setPreStep,
  privacy_disclaimer,
}) => {
  const { deviceType } = useContext(StaticPropsContext);
  const UnregisteredWithoutTabsComponent =
    deviceType === 'mobile' ? UnregisteredWithoutTabsMobile : UnregisteredWithoutTabsDesktop;
  const storedValues = getForm(localStorageKey);
  const [isReady, setIsReady] = useState(!isObjectEmpty(storedValues));
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState(null);

  useEffect(() => {
    const posting = localStorage.getItem(IS_POSTING);
    if (!posting) {
      if (!isReady && !preLogin) {
        setPreStep(ACCESS);
        return;
      }
      if (preLogin) {
        setPreStep(PRELOGIN);
      } else {
        setPreStep(SHIELD);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetSteps = () => {
    stepBack();
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    onSubmit({
      filledForm: {
        name: storedValues.name,
        last_name: storedValues.last_name,
        email: storedValues.email,
        identification_number: storedValues.identification_number,
        phone: storedValues.phone,
      },
      recaptchaTokenV2,
    });
  };

  const onRemove = () => {
    localStorage.removeItem(localStorageKey);
    Object.entries(form).forEach(([field, value]) => {
      if (value !== '' && form[field] && (form[field].value !== undefined || form[field].value !== '')) {
        form[field].value = '';
      }
    });
    updateForm(form);
    setIsReady(false);
  };

  const removeForm = () => {
    onRemove();
    setPreStep(ACCESS);
  };

  const replaceText = {
    name: {
      label: { text: ` ${storedValues.name} ` },
      type: 'highlighted',
    },
    last_name: {
      label: { text: storedValues.last_name },
      type: 'highlighted',
    },
  };

  const chooseStep = () => (isReady ? setPreStep(SHIELD) : setPreStep(ACCESS));
  return (
    <UnregisteredWithoutTabsComponent
      updateForm={updateForm}
      onSubmit={onSubmit}
      messages={messages}
      recaptchaPublicKey={recaptchaPublicKey}
      url={url}
      isPostingQuotation={isPostingQuotation}
      stepBack={resetSteps}
      selectedUnit={selectedUnit}
      selection_info={selection_info}
      selectedModel={selectedModel}
      onRemove={onRemove}
      replaceText={replaceText}
      handleOnSubmit={handleOnSubmit}
      isReady={isReady}
      setRecaptchaTokenV2={setRecaptchaTokenV2}
      recaptchaTokenV2={recaptchaTokenV2}
      storedValues={storedValues}
      preLogin={preLogin}
      preUnregistered={preUnregistered}
      chooseStep={chooseStep}
      form={form}
      step={preStep}
      setStep={setPreStep}
      removeForm={removeForm}
      privacyDisclaimer={privacy_disclaimer}
    />
  );
};

UnregisteredWithoutTabs.propTypes = {
  form: shape({}).isRequired,
  updateForm: func.isRequired,
  onSubmit: func.isRequired,
  messages: shape({}),
  recaptchaPublicKey: string,
  url: string,
  isPostingQuotation: bool,
  stepBack: func,
  selectedModel: shape({}),
  selectedUnit: shape({
    name: shape({
      text: string,
    }),
  }),
  selection_info: shape({
    title: shape({
      text: string,
    }),
    collapsible_info: shape({
      icon: shape({
        id: string,
      }),
    }),
  }),
  preLogin: shape({}),
  preUnregistered: shape({}),
  privacy_disclaimer: shape({}),
  setPreStep: func,
  preStep: string,
};

UnregisteredWithoutTabs.defaultProps = {
  className: null,
  preview_form: null,
  messages: null,
  recaptchaPublicKey: null,
  url: null,
  isPostingQuotation: false,
  privacy_disclaimer: null,
  stepBack: null,
  selectedModel: null,
  selectedUnit: null,
  selection_info: null,
};

export default UnregisteredWithoutTabs;
