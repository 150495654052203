import React from 'react';
import { shape, string, number, arrayOf } from 'prop-types';
import classNames from 'classnames';
import StyledLabel from '../../styled-label';
import Image from '../../image';

const IMAGE_WIDTH = 27;
const IMAGE_HEIGHT = 25;

const Brands = ({ namespace, brands }) => {
  if (!brands) {
    return null;
  }

  return (
    <div className={`${namespace}__brands`}>
      {brands.map(brand => {
        const pictureSize = {
          height: brand.height || IMAGE_HEIGHT,
          width: brand.width || IMAGE_WIDTH,
        };
        return (
          <Image
            data-testid="brand-images"
            className={classNames(`${namespace}__image`)}
            key={brand.id}
            id={brand.id}
            src={brand.url.src}
            alt={brand.alt}
            width={pictureSize.width}
            height={pictureSize.height}
          />
        );
      })}
    </div>
  );
};

const OfferedBy = ({ namespace, offered_by }) => {
  const offeredByClassNames = classNames({
    [`${namespace}__offered`]: !offered_by.brands,
    [`${namespace}__offered-brands`]: offered_by.brands && offered_by.brands.length > 0,
  });

  const pictureSize = {
    height: (offered_by.title.picture && offered_by.title.picture.height) || IMAGE_HEIGHT,
    width: (offered_by.title.picture && offered_by.title.picture.width) || IMAGE_WIDTH,
  };

  return (
    <div className={offeredByClassNames}>
      {offered_by.title && (
        <div className={classNames(`${namespace}__offered-by`)}>
          {offered_by.title.label && (
            <StyledLabel
              as="span"
              color={offered_by.title.label.color}
              font_size={offered_by.title.label.font_size}
              font_family={offered_by.title.label.font_family}
              text={offered_by.title.label.text}
              className={`${namespace}__offered-by__label`}
            />
          )}
          {offered_by.title.picture && (
            <Image
              data-testid="offeredby-images"
              className={classNames(`${namespace}__image`)}
              id={offered_by.title.picture.id}
              src={offered_by.title.picture.url.src}
              alt={offered_by.title.picture.alt}
              width={pictureSize.width}
              height={pictureSize.height}
            />
          )}
        </div>
      )}
      <Brands namespace={namespace} brands={offered_by.brands} />
    </div>
  );
};

Brands.propTypes = {
  brands: arrayOf(
    shape({
      id: string.isRequired,
      alt: string,
      url: shape({
        src: string,
      }),
      width: number,
      height: number,
    }),
  ),
  namespace: string,
};

Brands.defaultProps = {
  brands: null,
  namespace: null,
};

OfferedBy.propTypes = {
  offered_by: shape({
    title: shape({
      picture: shape({
        id: string.isRequired,
        alt: string,
        url: shape({
          src: string,
        }),
        width: number,
        height: number,
      }),
      label: shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
    }),
  }).isRequired,
  namespace: string,
};

OfferedBy.defaultProps = {
  namespace: null,
};

export default OfferedBy;
